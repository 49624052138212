import React, {useState,  useEffect } from "react";
import { StyleSheet, Text, View, Image,Dimensions } from "react-native";
import { Colors } from "../styles/colors";



function ImageComponent({ image, position }) {
    const {windowWidth, windowHeight,limitHeight} = Dimensions.get('window');
   const [parentWidth, setParentWidth] = useState(windowWidth);
    //debugger;
    const handleSize = (event) => {
        const { x, y, width, height } = event.nativeEvent.layout;
        setParentWidth(width);
    }
    
    const [width, setWidth] = useState(0);
    const [height, setHeight] = useState(0);
  const propstyles = {};
  if (position) {
    switch (position) {
      case "center":
        propstyles.justifyContent = "center";
        break;
      case "left":
        propstyles.justifyContent = "flex-start";
        break;
      case "right":
        propstyles.justifyContent = "flex-end";
        break;
      default:
        break;
    }
  }
  useEffect(() => {
    Image.getSize(image, (w, h) => {
      //  debugger;
        if(w>parentWidth){
            const ratio = parentWidth/w;
            setWidth(parentWidth);
            if(limitHeight && h*ratio>h){

                setHeight(h);
            }else{

                setHeight(h*ratio);
            }
        }else{
            setWidth(w);
            setHeight(h);
        }
       
        // if (width * 3/2 < height)
        //     height = width * 3/2;
        // this.setState({aspectRatio: width / height});
    });
    
  }, [parentWidth]);

  const imageStyle =  {
    resizeMode: limitHeight?'cover':'contain',
    maxWidth:'100%',
    width:width,
    height:height
  };
  return (
     <View style={StyleSheet.flatten([styles.imageContainer, propstyles])} onLayout={handleSize}>
      <Image
        style={imageStyle}
        source={{
          uri: image,
        }}
      />
     </View>
  );
}
const styles = StyleSheet.create({
  imageContainer: {
    flex: 1,
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
  //  backgroundColor:Colors.blue
  },
  
});

export default ImageComponent;
