import React, { useState } from "react";
import {
  View,
  Text,
  TouchableOpacity,
  StyleSheet,
} from "react-native";
//import { useGlobalState } from "../services/store";
import ChapterNavigation from "../services/chapternavigation.service";
import { Colors, Buttons, Texts,Layout } from "../styles";
import StepProgress from "../components/StepProgress";
import i18n from "../services/translations";
import { useGlobalState } from "../services/store";
import {
  Icon,
} from "react-native-elements";
import { LinearGradient } from 'expo-linear-gradient';
const BottomChapterNav = (props) => {
    const { state, dispatch } = useGlobalState();
    const [navHeight, setNavHeight] = useState(200);
    // const {
    // //  chaptersAvailable,
    // //  outline,
    //  // selectedChapter,
    //  // mainChapter,
    //   nextChapter,
    //  // previousChapter,
    //  // closeModal,
    // } = ChapterNavigation();
    const find_dimesions = (layout, setState) => {
      const { x, y, width, height } = layout;
      setState(height);
      // const wantedWidth = width-50;
      // if(subchapter.image && wantedWidth>0 && wantedWidth!==viewWidth){
      //     setViewWidth(wantedWidth);
      // }
    };
   const nextstyle = {
      borderRadius:40,
      backgroundColor:props.isDone?Colors.white:Colors.opaqueWhite,
    //  opacity:0.3,
      height:40,
      width:40,
      flex:-1,
      flexDirection:"column",
      justifyContent:"center",
      alignItems:"center"
    };
  return (

     <View style={styles.container}>
       <LinearGradient
        // Background Linear Gradient
        colors={['rgba(60,60,60,0.0)','rgba(60,60,60,0.2)', 'rgba(60,60,60,0.7)']}
        style={{
          position: "absolute",
          top: 0,
          left: 0,
         right: 0,
         height:navHeight
         }}
      />
              <View
                style={styles.bottomNav} 
              >
                    
                  <View style={styles.bottomNavInner} onLayout={(event) => {
              find_dimesions(event.nativeEvent.layout, setNavHeight);
            }}>
                    {/* <Text style={styles.bottomChapterHeading}>
                      {state.selectedChapter && state.selectedChapter.title}
                    </Text> */}
                    {/* <View style={styles.horizontalSpace}> */}
                      {/* <Text style={styles.bottomSubchapterHeading}>
                        {state.selectedSubchapter && state.selectedSubchapter.title}
                      </Text> */}
            
                    {/* </View> */}
                    <View style={styles.hidden}></View>
                    <StepProgress />
                    <TouchableOpacity style={nextstyle} onPress={() => props.gotoNext()}>
                        <Icon
                          color={props.isDone?Colors.darkerGrey:Colors.white}
                          name="arrow-forward"
                          size={20}
                          type="material"
                        />
                      </TouchableOpacity>
                  </View>
                  </View>
       </View>
        
  );
};
var styles = StyleSheet.create({
   
    
      container: {
       // flex: 1,
          // flex: 1,
    // flexGrow:0,
     flexDirection: "row",
     justifyContent: "center",
  //  backgroundColor:Colors.beige,
    // alignItems:"center",
     position: "absolute",
     bottom: 0,
     left: 0,
    right: 0,
     zIndex: 10,
   // height:50,
   //  marginTop: 30,
    
        // flexDirection: "column",
        // justifyContent: "space-between",
        // alignItems: "stretch",
        // position: "relative",
       // backgroundColor:Colors.salmon
      },
      
     
     
      bottomNav: {
        flex: 1,
        flexDirection: "row",
       // borderTopColor: Colors.lightGrey,
     //   borderTopWidth: 1,
        justifyContent: "center",
         alignItems: "stretch",
        
       // paddingTop: 20,
       // paddingBottom: 20,
    
       // backgroundColor: Colors.pink,
        //maxHeight: 150,
      },
      bottomNavInner: {
        flex: 1,
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
        paddingLeft:20,
        paddingRight:20,
        paddingTop:40,
        paddingBottom:40,
      //  marginBottom:30,
      //  maxWidth: 1400,
      },
      // bottomNavHeadings: {
      //   flex: 1,
      //   flexDirection: "row",
      //   justifyContent: "center",
      //   alignItems: "stretch",
      // },
    
      // bottomChapterHeading: {
      //   fontSize: 18,
      //   color: Colors.grey,
      // },
      // bottomSubchapterHeading: {
      //   fontSize: 26,
      //   color: Colors.darkGrey,
      // },
      // horizontalSpace: {
      //   flex: 1,
      //   flexDirection: "row",
      //   justifyContent: "space-between",
      //   alignItems: "center",
      // },
   
      hidden:{
        ...Layout.hiddenContainer,
        width:40,
        height:40
      },
      // gradient:{
      //   position: "absolute",
      //   bottom: 0,
      //   left: 0,
      //  right: 0,
      //  height:200
      // }
  });
export default BottomChapterNav;
