import React, { useState, useRef, useContext,useEffect } from 'react';
import {StyleSheet, View, Text, Image ,TextInput, TouchableOpacity} from 'react-native';
import {useGlobalState} from '../services/store';
import { Link } from '../routing';
import { Redirect } from 'react-router-dom';
import Login from '../components/Login';
import Header from '../components/Header';
import { useNavigation } from '@react-navigation/native';
import AuthService from "../services/auth.service";
import { ScrollView, useWindowDimensions } from "react-native";
import HTML from "react-native-render-html";
import Markdown from 'react-native-markdown-display';
//import Navigation from './Navigation';
import i18n from '../services/translations';
import Renderer from '../helpers/renderer';

const Register = props => {
//     const htmlContent = `
//     <h2>test</h2>
// sd<strong>qd qd</strong>qwd
// <ul>
//  	<li>a</li>
//  	<li>b</li>
//  	<li>c asdqo qqd</li>
// </ul>
// &nbsp;
// `;
const navigation = useNavigation();
const navigateToEducation = () =>{
    navigation.navigate('Education');
}
  //  const [state, dispatch] = useContext(useGlobalState());
  const {state, dispatch} = useGlobalState();
  const [registrationToken, setRegistrationToken] = useState();
  const [isLoggedIn,setIsLoggedIn] = useState(false);
  const contentWidth = useWindowDimensions().width;
  const [viewContent, setViewContent] = useState([]);
  const [title, setTitle] = useState("");
  const [chapter, setChapter] = useState("");
    useEffect(() => {
        setIsLoggedIn(state.user && state.user.token);
        // axios.get('/posts.json')
        //     .then(response => {
        //         const postsData = response.data;
        //         dispatch({type: 'SET_POSTS', payload: postsData});
        //     })
        //     .catch(error => {
        //         dispatch({type: 'SET_ERROR', payload: error});
        //     });
    }, [state.user]);
    useEffect(() => {
        const params = props.route.params;
        if(props.route.params !==undefined && props.route.params.registrationtoken!==undefined){
            setRegistrationToken(props.route.params.registrationtoken);
        }
       //debugger;
    }, []);
    useEffect(() => {
       const viewData = state.publiccontent.filter(view => view.id =="registration");
       if(viewData&&viewData.length>0){
        setViewContent(viewData[0][state.locale].view_content);
        if(viewData[0][state.locale].title){
            setTitle(viewData[0][state.locale].title)
        }
        if(viewData[0][state.locale].chapter){
            setTitle(viewData[0][state.locale].chapter)
        }
       
       }
       
    }, [state.publiccontent,state.locale]);
    
   // const navigation = useNavigation();
    // const auth = () =>{
    //     return (
    //         <View>
   
    //         <Text>Utbildningen är här </Text>
       
    //     </View>
    //     )
    // }
    // const unauth = () => {
    //     return (
    //         <Redirect to='Login' />
    //     )
    // }
    // const user =  AuthService.getCurrentUser();
    // if(user===null || user.token ===null){
    //     props.navigation.navigate('Login');
    //    // navigation.navigate('Login')
    // }
    
    // const backButton = (
    //     <View>
    //       {/* <Link to="/">
    //         <Text>Go Back</Text>
    //       </Link> */}
    //     </View>
    //   );
    //   if (!props.selected) {
    //     return (
    //       <View>
    //         {backButton}
    //         <Text>No Pokemon selected</Text>
    //       </View>
    //     );
    //   }
    //   const {
    //     selectedPokemon: { name, number, type, photoUrl }
    //   } = props.selected
    // if(state.user!=null && state.user.token!=null){
    //     return auth();
    // }else{
    //     return unauth();
    // }

  return (
    <View style={styles.container}>
        <Text>{chapter}</Text>
        <Header>{title}</Header>
        {viewContent.map((component, index) => Renderer(component,index))}
      {/* <HTML source={{ html: htmlContent }} contentWidth={contentWidth} /> */}

          {!isLoggedIn && <Login registrationToken={registrationToken} isRegisterPage={true} loginCallback={navigateToEducation} />}
  </View>


  );
};

const styles = StyleSheet.create({
    container: {
      flex: 1,
     // marginTop:20,
    //   height:'100%',
    //   backgroundColor: '#003f5c',
      alignItems: 'center',
      justifyContent: 'flex-start',
    //  overflow:'visible',
    },
    logo:{
      fontWeight:"bold",
      fontSize:50,
      color:"#fb5b5a",
      marginBottom:40
    },
    inputView:{
      width:"80%",
      backgroundColor:"#aaa",
      borderRadius:25,
      height:50,
      marginBottom:20,
      justifyContent:"center",
      padding:20
    },
    inputText:{
      height:50,
      color:"white"
    },
    forgot:{
      color:"white",
      fontSize:11
    },
    loginBtn:{
     // width:"80%",
      backgroundColor:"#88aaf7",
      borderRadius:10,
      width:'80%',
      marginLeft:'auto',
      marginRight:'auto',
      textAlign:'center',
     // height:50,
     padding:'10px',
      alignItems:"center",
      justifyContent:"center",
     // marginTop:40,
      marginBottom:10
    },
    loginText:{
      color:"white"
    }
  });
export default Register;