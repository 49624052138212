import React from 'react';
import 'react-native-gesture-handler';
import { Button} from 'react-native-paper';
//import {createStackNavigator} from 'react-navigation-stack';
import { createStackNavigator } from '@react-navigation/stack';
import { NavigationContainer, HeaderStyleInterpolators,CardStyleInterpolators } from '@react-navigation/native';
import {createAppContainer} from 'react-navigation';
import {createBrowserApp} from '@react-navigation/web';
import CustomHeader from '../components/CustomHeader';

import Education from "./Education";
import Login from "../components/Login";
import Profile from "./Profile";
import {Platform, Alert} from "react-native";
import Home from './Home';
import Register from './Register';
import i18n from '../services/translations';
import EducationStep from './EducationStep';

const isWeb = Platform.OS === 'web';
const linking = {
    prefixes: ['http://app.insightgap.com/', 'insightgap://'],
    config: {
      screens: {   
        Main:{
          screens:{
            Home: 'home',
            Register: 'register/:registrationtoken?',
            Education: 'education',
            Profile: 'profile',
          }
        },
      //  Login:'login',
       Chapter:'education/chapter/:step?',
       
       // Settings: ':id/blog',
      }
    },
  };
  const styles = {
    header: {
        backgroundColor:'transparent',
      borderBottomWidth: 0,
      shadowColor: 'transparent',
      elevation: 0,
    },
  };

//   const EducationNavigationStack = createStackNavigator();
//   const EducationStackScreen = () => {
//     return (
//       <EducationNavigationStack.Navigator>
//         <PopupNavigationStack.Screen name="Navigation" component={Navigation} />
//       </EducationNavigationStack.Navigator>
//     );
//   };
const MainStack = createStackNavigator();
const MainNavigation = props =>{
  return(
    // <NavigationContainer linking={linking} >
<MainStack.Navigator
    initialRouteName="Education"
    
    screenOptions={{
      animationEnabled:true,
      headerTransparent:true,
      headerMode:'screen',
      header: (props) => <CustomHeader {...props} />,

    }}
  >
          <MainStack.Screen
      name="Education"
     // path="/"
      initialParams={props.params}
      component={Education}
      options={{
    //   headerShown: false,
        gestureEnabled: false,
        title: i18n.t('navigation.education'),
      }}
    />
    <MainStack.Screen
      name="Home"
    //  path="/home"
      component={Home}
      initialParams={props.params}
      options= {({route,navigation})=>({
        title: i18n.t('navigation.home'),
      })}
    />
     <MainStack.Screen
      name="Register"
    // path="register"
      component={Register}
      options={{
        title: i18n.t('navigation.register'),
      }}
    />
    <MainStack.Screen
      name="Profile"
    //  path="profile"
      component={Profile}
      options={{
        title: i18n.t('navigation.profile'),
      }}
    />
  </MainStack.Navigator>
  // </NavigationContainer>
  )

}
//export default MainNavigation;
 const Stack = createStackNavigator();
 const Navigation = props =>{
    return(
        <NavigationContainer linking={linking} >
    <Stack.Navigator mode="modal"
    
    screenOptions={{
      animationEnabled:true

    }}>
      <Stack.Screen
        name="Main"
       // path="/"
        component={MainNavigation}
        options={{ headerShown: false }}
      //options={{ headerShown: false }} 
      />
      <Stack.Screen name="Chapter" component={EducationStep}   initialParams={props.params} options={{ headerShown: false }}  />
    </Stack.Navigator>
    </NavigationContainer>
    )

}

 export default Navigation;



