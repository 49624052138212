import React from 'react';
import {StyleSheet, Text, View } from 'react-native';
import { Texts,Colors } from '../styles'
import SubChapter from './SubChapter'

const Chapter = ({chapter,setSelected,chapterindex,selectedChapter}) => {
        return (
            <View style={styles.container}>
                <Text style={Texts.chapterHeading}>{chapter.title}</Text>
                
                <View style={styles.subchaptercontainer}>
                {chapter.subchapters && chapter.subchapters.map((subchapter, index) => (<SubChapter key={subchapter.id} selectedChapter={ selectedChapter} chapter={chapter} index={[chapterindex,index]} subchapter={subchapter} setSelected={setSelected} />))}
                 </View>
                
            </View>     
        )
}

const styles = StyleSheet.create({
    container:{
        display:"flex",
        flex:1,
        flexDirection:"column",
        justifyContent:"flex-start",
        alignContent:"flex-start",
    },
    subchaptercontainer:{
        marginTop:10,
        marginBottom:10,
        flex:1,
        flexDirection:"column",
        justifyContent:"flex-start",
        alignItems:"stretch",
    },
});
export default Chapter;