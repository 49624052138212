import {Colors } from './colors';
export const base = {
    // backgroundColor:Colors.darkBlue,
    // color:Colors.white,
    // textAlign:"center",
    fontSize:16,
    color:Colors.darkGrey,
   
  };
  export const bright = {
    ...base,
    color:Colors.white
   };
export const heading = {
   fontSize:25
  };
  
  
  export const chapterHeading = {
    ...base,
    ...heading,
    fontSize:12,
    color:Colors.grey,
    textTransform:"uppercase"
  };
  // export const chapterHeading = {
  //   ...base,
  //   ...heading,
  //   color:Colors.darkBlue,
  //   textTransform:"uppercase"
  // };
  export const subchapterHeading = {
    ...base,
    ...heading,
    fontSize:15,
    color:Colors.black,
    fontWeight:"bold"
  };
  export const educationTopHeading = {
    ...base,
    ...heading,
    fontSize:60
  };