import React, { useState, useEffect } from "react";
import {
  View,
  Text,
  TouchableOpacity,
  ScrollView,
  Dimensions,
} from "react-native";
import { useGlobalState } from "../services/store";
import Login from "../components/Login";
import Chapter from "../components/Chapter";
import Header from "../components/Header";
import { Colors, Texts } from "../styles";
import i18n from "../services/translations";
import { useNavigation, useRoute } from "@react-navigation/native";
import { Icon } from "react-native-elements";
import ChapterNavigation from "../services/chapternavigation.service";
import Renderer from "../helpers/renderer";
const Education = (props) => {
  const test = "";
  const { findIndexForSubChapter } = ChapterNavigation();

  const [loginContent, setLoginContent] = useState([]);
  const navigation = useNavigation();
  const route = useRoute();
  const { state, dispatch } = useGlobalState();
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [viewContent, setViewContent] = useState([]);
  const [title, setTitle] = useState(undefined);
  const [maxWidth, setMaxWidth] = useState(Dimensions.get("window").width);
  useEffect(() => {
    const updateLayout = () => {
      setMaxWidth(Dimensions.get("window").width);
    };
    Dimensions.addEventListener("change", updateLayout);

    return () => {
      Dimensions.removeEventListener("change", updateLayout);
    };
  }, []);
  useEffect(() => {
    const loginData = state.publiccontent.filter((view) => view.id == "login");
    if (loginData && loginData.length > 0) {
      // debugger;
      setLoginContent(loginData[0][state.locale].view_content);
      if (loginData[0][state.locale].title) {
        setTitle(loginData[0][state.locale].title);
      }
    }
  }, [state.publiccontent, state.locale]);
  useEffect(() => {
    setIsLoggedIn(state.user && state.user.token);
  }, [state.user]);

  useEffect(() => {
    // debugger;
    const viewData =
      state.protectedcontent?.views &&
      state.protectedcontent.views.filter(
        (view) => view.id == "educationcontent"
      );
    if (viewData && viewData.length > 0) {
      setViewContent(viewData[0][state.locale].view_content);
    }
  }, [state.protectedcontent, state.locale]);

  const showChapter = () => {
    if (state.selectedIndex === null) {
      navigation.navigate("Chapter", { step: 0 });
    } else {
      navigation.navigate("Chapter", { step: state.selectedIndex });
    }
  };
  const openChapter = (chapterid) => {
    const index = findIndexForSubChapter(chapterid);
    navigation.navigate("Chapter", { step: index });
  };
  return (
    <View
      style={{
        flex: 1,
      }}
    >
      <View
        style={{
          flex: 1,
          position: "absolute",
          top: 50,
          left: 0,
          right: 0,
          bottom: 100,
        }}
      >
        <ScrollView
          contentContainerStyle={{
            flex: 1,
            flexGrow: 1,
            padding: 20,
            paddingBottom: 30,
          }}
        >
          <View
            style={{
              flex: 1,
            }}
          >
            {!isLoggedIn && (
              <View
                style={{
                  flex: 1,
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                {title && <Text style={Texts.heading}>{title}</Text>}
                {loginContent &&
                  loginContent.map((component, index) =>
                    Renderer(component, index)
                  )}
                <Login isRegisterPage={false} />
              </View>
            )}
            {isLoggedIn && (
              <View
                styles={{
                  flex: 1,
                  flexDirection: "column",
                }}
              >
                {viewContent &&
                  viewContent.map((component, index) =>
                    Renderer(component, index)
                  )}
                <Header align="center">{i18n.t("education.content")}</Header>

                {state.outline &&
                  state.outline.chapters &&
                  state.outline.chapters.map((chapter, index) => (
                    <Chapter
                      key={chapter.id}
                      chapterindex={index}
                      chapter={chapter}
                      selectedChapter={state.selectedSubchapter?.id}
                      setSelected={openChapter}
                    />
                  ))}
              </View>
            )}
          </View>
        </ScrollView>
      </View>
      <View
        style={{
          backgroundColor: Colors.darkerBlue,
          position: "absolute",
          bottom: 0,
          left: 0,
          right: 0,
          height: 100,
        }}
      >
        <View
          style={{
            flex: 1,
            flexDirection: "row",
            justifyContent: "flex-end",
            alignContent: "center",
          }}
        >
          <View
            style={{
              flex: -1,
              flexDirection: "column",
              justifyContent: "center",
            }}
          >
            <Text
              style={{
                color: Colors.white,
                fontSize: 19,
                marginRight: 20,
                lineHeight: 40,
                height: 40,
              }}
            >
              {i18n.t("buttons.begin")}
            </Text>
          </View>
          <TouchableOpacity
            onPress={showChapter}
            style={{
              marginRight: 20,
              flex: -1,
              flexDirection: "column",
              justifyContent: "center",
            }}
            disabled={state.outline?.chapters?.length === 0}
          >
            <View
              style={{
                borderRadius: 40,
                backgroundColor: Colors.white,
                height: 40,
                width: 40,
                // flex:1,

                flex: -1,
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Icon
                color={Colors.darkerGrey}
                name="arrow-forward"
                size={20}
                type="material"
              />
            </View>
          </TouchableOpacity>
        </View>
      </View>
    </View>
  );
};
export default Education;
