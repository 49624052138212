import React from 'react';
import { Colors, Buttons, Texts } from "../styles";
import { StyleSheet, Text, View, Dimensions } from 'react-native';
import {
    Icon,
  } from "react-native-elements";
{/* <Icon
                    color={Colors.white}
                    name={props.icon.replace("fas ","").replace("fa-","")}
                    size={60}
                    type="font-awesome"
                /> */}
  //debugger;

function UnderlineIngress (props) {
    let externalstyle = {};
    // if(props.style){
    //     externalstyle = props.style;
    // }
    const styles = {
        ingress: {
            fontWeight: "bold",
            marginTop:20,
            marginBottom:20,
            flex:1,
            flexDirection:"row",
            justifyContent:"space-between",
            alignItems:"flex-start",
            ...externalstyle
        },
        underline:{
            flex:-1,
            ...Texts.bright,
            marginRight:15,
            textDecorationLine:"underline",
            ...externalstyle,
            flexWrap:"no-wrap"
            //fontWeight:"bold"
        },
        text:{
            flex:1,
            ...Texts.bright,
            ...externalstyle
            //fontWeight:"bold"
        }
    };
        return (    
            <View  style={styles.ingress}>
                <Text style={styles.underline}>{props.underlined}</Text>
                <Text style={styles.text}>{props.ingress}</Text>
            </View>)
}




export default UnderlineIngress;