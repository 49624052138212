import React, { useState, useRef, useContext, useEffect } from "react";
import {
  View,
  Text,
  Image,
  StyleSheet,
  Dimensions,
  TouchableOpacity,
} from "react-native";
import { useGlobalState } from "../services/store";
//import ChapterNavigation from "../services/chapternavigation.service";
import { Colors, Buttons, Texts } from "../styles";

const StepProgress = (props) => {
//  const { gotoChapter } = ChapterNavigation();
 // const [currentSubchapters, setCurrentSubchapters] = useState(null);
  //const [chapterCount, setChapterCount] = useState(0);
  const [chapterIndex, setChapterIndex] = useState(0);
 // const [subStepIndex, setSubStepIndex] = useState(0);
  const { state, dispatch } = useGlobalState();

  useEffect(() => {
    if (state.selectedChapter && state.selectedChapter.subchapters) {
   //   setCurrentSubchapters(state.selectedChapter.subchapters);
    //  setChapterCount(state.selectedChapter.subchapters.length);
        const chindex =  state.selectedChapter.subchapters.findIndex((subchapter, index) => {
          return subchapter.id === state.selectedSubchapter.id;
        });
        setChapterIndex(
        chindex
        );
    //  debugger;
      //   if(state.selectedSubstep){
      //     const index = state.selectedSubchapter.substeps.findIndex((substep, index) => {
      //       return substep.id === state.selectedSubstep.id;
      //     });
      //     setSubStepIndex( index);
      // }else{
      //   setSubStepIndex(0);
      // }   
   
    } else {
     // setCurrentSubchapters([]);
     // setChapterCount(0);
      setChapterIndex(0);
    }
  }, [state.selectedChapter, state.selectedSubchapter,state.selectedSubstep]);

  const stepstyle = {
    borderRadius: 25,
    height: 10,
    width: 10,
    marginTop: 10,
    marginBottom: 10,
    marginLeft: 5,
    marginRight: 5,
    backgroundColor: Colors.opaqueWhite,
  };
  const subStepstyle = {
    height: 10,
    width: 10,
    marginTop: 10,
    marginBottom: 10,
    marginLeft: 0,
    marginRight: 0,
    backgroundColor: Colors.opaqueWhite,
  };
  const   selectedEndStepstyle ={
    borderTopRightRadius: 25,
    borderBottomRightRadius: 25,
    height: 10,
    width: 10,
    marginTop: 10,
    marginBottom: 10,
    marginLeft: 0,
    marginRight: 5,
    //opacity:0.35,
    backgroundColor: Colors.opaqueWhite,
  };
  const   selectedStartStepstyle= {
    borderTopLeftRadius: 25,
    borderBottomLeftRadius: 25,
    height: 10,
    width: 10,
    marginTop: 10,
    marginBottom: 10,
    marginLeft: 5,
    marginRight: 0,
    //opacity:0.35,
    backgroundColor: Colors.opaqueWhite,
  };
  const renderStep = (subchapter, index) => {
    const opacitystyle = {};
    if (index === chapterIndex) {
      opacitystyle.backgroundColor = Colors.white;
      const selectedSubstepIndex = subchapter.substeps.findIndex((substep, index) => {
        return substep.id === state.selectedSubstep.id;
      });
      if (subchapter.substeps && subchapter.substeps.length > 1) {
        return (
          <View
            key={`progressstep_${subchapter.id}`}
            style={styles.showSubsteps}
          >
           
           
            {subchapter.substeps.map((substep, innerindex) => {
            
              const substepSelectedStyle = {};
              if (innerindex <= selectedSubstepIndex) {
                substepSelectedStyle.backgroundColor = Colors.white;
              }
              if(innerindex ===0){
                return <View  key={`substep_${substep.id}`} style={{...selectedStartStepstyle,...substepSelectedStyle}} />
              }else if(innerindex ===subchapter.substeps.length-1){
                return <View  key={`substep_${substep.id}`} style={{...selectedEndStepstyle,...substepSelectedStyle}} />
              }
              else{
                return (
                  <View
                    key={`substep_${substep.id}`}
                    style={{ ...subStepstyle, ...substepSelectedStyle }}
                  />
                );
              }
            
            })}
           
          </View>
        );
      } else {
        return (
          <View
            key={`progressstep_${subchapter.id}`}
            style={{ ...stepstyle, ...opacitystyle }}
          />
        );
      }
    } else {
      return (
        <View
          key={`progressstep_${subchapter.id}`}
          style={stepstyle}
        />
      );
    }
  };
  return (
    <View style={styles.container}>
      {state.selectedChapter &&
        state.selectedChapter.subchapters.map((subchapter, index) => {
          return renderStep(subchapter, index);
        })}
    </View>
  );
};
var styles = StyleSheet.create({
  container: {
    flex: -1,
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
  },


  showSubsteps: {
    flex: -1,
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
  },
});
export default StepProgress;
