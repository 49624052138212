import React from 'react';
import { Colors, Buttons, Texts } from "../styles";
import { StyleSheet, Text, View, Dimensions } from 'react-native';
import {
    Icon,
  } from "react-native-elements";

function Paragraph (props) {
    let externalstyle = {};
    if(props.style){
        externalstyle = props.style;
    }
  //  let maxWidth;
    // if(props.unit==="percent"){
    //     maxWidth= `${props.maxWidth}%`;
    // }else{
    //     maxWidth = props.maxWidth;
    // }
    const styles = {
        paragraph: {
           // fontWeight: "bold",
            marginTop:20,
            marginBottom:20,
            flex:1,
            flexDirection:"row",
            justifyContent:"space-between",
           // justifyContent:"center",
            alignItems:"center",
          //  maxWidth:maxWidth,
            ...externalstyle
        },
        text:{
            ...Texts.base,
            flexWrap: 'wrap',
            ...externalstyle
            //fontWeight:"bold"
        }
    };
        return (    
            <View  style={styles.paragraph}>
                <Text style={styles.text}>{props.text}</Text>
            </View>)
}




export default Paragraph;