import * as Localization from 'expo-localization';
import i18n from 'i18n-js';
i18n.defaultLocale = 'sv';
i18n.locale = Localization.locale;
i18n.fallbacks = true;

i18n.translations = { en:{
    appName: "Insightgap",
    appTitle: "%{appName} - %{view}",
    profile:"Profile",
    navigation: {
      Home: "Home",
      Profile: "Profile",
      Register: "Register",
      Login: "Login",
      Education:"Education"
    },
    inputs:{
      email:"Email",
      password:"Password",
      customercode:"Customer code"
    },
    labels:{
      email:"E-mail (work)",
      password:"Password",
      registerpassword:"Ange ett personligt lösenord",
      customercode:"Kod (Finns i mailutskick)"
    },
    buttons:{
        login:"Login",
        logout:"Log out",
        register:"Register",
        begin:"Begin",
        continue:"Continue",
        playagain:"Play again",
        showmore:"Show more"
    },
    educationstep:{
      heading:"Insightgap Self Discovery"
    },
    home: {
      welcome: "Welcome to %{appName}!"
    },
    education:{
      content:"Content",
    },
    emotions:{
      variant1:{
        joy:"joy",
        lust:"lust",
        fear:"fear",
        anger:"anger",
        shame:"shame",
        sadness:"sadness",
        distaste:"distaste"
      }
    }
    
  }, sv:{
    appName: "Insightgap",
    appTitle: "%{appName} - %{view}",
    profile: "Användarprofil",
    navigation: {
        Home: "Hem",
        Profile: "Användarprofil",
        Register: "Registera",
        Login: "Logga in",
        Education:"utbildning"
      },
      inputs:{
        email:"Epost",
        password:"Lösenord",
        customercode:"Kundkod"
      },
      labels:{
        email:"E-postadress (arbete)",
        password:"Lösenord",
        registerpassword:"Ange ett personligt lösenord",
        customercode:"Kod (Finns i mailutskick)"
      },
    buttons:{
        login:"Logga in",
        logout:"Logga ut",
        register:"Ta del av erbjudandet",
        begin:"Börja nu",
        continue:"Fortsätt",
        playagain:"Spela igen",
        showmore:"Visa mer"
    },
    educationstep:{
      heading:"Insightgap Self Discovery"
    },
    home: {
      welcome: "Welcome to %{appName}!"
    },
    education:{
      content:"Innehåll",
    },
    emotions:{
      variant1:{
        joy:"glädje",
        lust:"lust",
        fear:"rädsla",
        anger:"ilska",
        shame:"skam",
        sadness:"sorg",
        distaste:"avsmak"
      }
    }
  } };
  export default i18n;

// import LocalizedStrings from 'react-native-localization';
// export const DEFAULT_LANGUAGE = 'en';

// const translations = {
//   en: {
//     WELCOME: 'Welcome to React',
//     STEP1: 'Step One',
//     SEE_CHANGES: 'See Your Changes',
//     CHANGE_LANGUAGE: 'Change Language',
//     LANGUAGE_SETTINGS: 'Change Language',
//     BACK: 'Back'
//   },
//   de: {
//     WELCOME: 'Välkommen',
//     STEP1: '1. Schritt',
//     SEE_CHANGES: 'Änderungen ansehen',
//     CHANGE_LANGUAGE: 'Sprache wechseln',
//     LANGUAGE_SETTINGS: 'Sprache wechseln',
//     BACK: 'Zurück'
//   }
// };

// export default new LocalizedStrings(translations);