import React from 'react';
import { View, Text, FlatList,TouchableOpacity } from 'react-native';
const Home = props => {
    const handlePress = () => {
       // props.setSelected(pokemon);
       // props.navigation.navigate('Education');
      };
  return (
    <View style={{flex:1,justifyContent:"center",alignItems:"center"}}>
       {/* <FlatList
        keyExtractor={pokemon => pokemon.number}
        data={pokemon}
        renderItem={({ item }) => (
          <TouchableOpacity onPress={() => handlePress(item)}>
            <Text>{item.name}</Text>
          </TouchableOpacity>
        )}
      /> */}
        <TouchableOpacity onPress={() => handlePress()}>
            <Text>Gå till utbildning adw</Text>
          </TouchableOpacity>
    </View>
  );
};
export default Home;