import {Colors} from './colors';
export const base = {
    backgroundColor:Colors.darkBlue,
    color:Colors.white,
    textAlign:"center",
    textTransform:"uppercase"
  };
  
export const small = {
    paddingHorizontal: 10,
    paddingVertical: 12,
  };
  
  export const rounded = {
    borderRadius: 50
  };
  
  export const smallRounded = {
    ...base,
    ...small,
    ...rounded
  };