import React, { useState, useEffect } from "react";
import { useGlobalState } from "../services/store";
import {
  useNavigation,
  useRoute,
  StackActions,
  CommonActions,
} from "@react-navigation/native";

const ChapterNavigation = () => {
  const navigation = useNavigation();
 //const route = useRoute();
  const { state, dispatch } = useGlobalState();
 // const [selectedSubstepId, setSelectedSubstepId] = useState("");
 // const [selectedSubchapterId, setSelectedSubchapterId] = useState("");
  //const [pendingNavigation, setPendingNavigation] = useState(false);
//   const previousChapter = () => {
//     if (state.selectedSubchapter !== null && state.outline) {
//       const substepIndex = state.selectedSubchapter.substeps.findIndex(
//         (ch) => ch.id === state.selectedSubstep.id
//       );
//       if (substepIndex > 0) {
//         selectSubstep(
//           state.selectedSubchapter.id,
//           state.selectedSubchapter.substeps[substepIndex - 1].id
//         );
//       } else {
//         const allsubchapters = flattenOutline();
//         const currentIndex = allsubchapters.findIndex(
//           (ch) => ch.id === state.selectedSubchapter.id
//         );
//         if (currentIndex > 0) {
//           selectChapter(allsubchapters[currentIndex - 1].id, -1);
//         }
//       }
//     } else {
//     }
//   };
//   const nextChapter = () => {
//     if (state.selectedSubchapter !== null && state.outline) {
//       const substepIndex = state.selectedSubchapter.substeps.findIndex(
//         (ch) => ch.id === state.selectedSubstep.id
//       );
//       if (substepIndex < state.selectedSubchapter.substeps.length - 1) {
//         selectSubstep(
//           state.selectedSubchapter.id,
//           state.selectedSubchapter.substeps[substepIndex + 1].id
//         );
//       } else {
//         const allsubchapters = flattenOutline();
//         const currentIndex = allsubchapters.findIndex(
//           (ch) => ch.id === state.selectedSubchapter.id
//         );
//         if (allsubchapters.length > currentIndex + 1) {
//           selectChapter(allsubchapters[currentIndex + 1].id);
//         }
//       }
//     } else {
//     }
//   };
//   const flattenOutline = () => {
//     const flat = state.outline.chapters.reduce((res, chapter) => {
//       return res.concat(chapter.subchapters);
//     }, []);
//     return flat;
//   };
//   const getAllSubsteps = () => {
//       const subchapters = flattenOutline();
//     const flat = subchapters.reduce((res, subchapter) => {
//       return res.concat(subchapter.substeps);
//     }, []);
//     return flat;
//   };
//   const getIndexOfSubstep= (substepid) => {
//     const allsubsteps = getAllSubsteps();
//     const index = allsubsteps.findIndex(
//         (ch) => ch.id === substepid
//       );
//       return index;
// };
//   const selectSubstep = (chapterid, substepid) => {
//     const args = { chapterid: chapterid };
//     args["substepid"] = substepid;
//     setSelectedChapters(args.chapterid,args.substepid);
//    // navigation.push("Chapter", args);
//   };
    // const selectChapter = (chapterid, direction = 1) => {
    //     const args = { chapterid: chapterid };
    //     const allsubchapters = flattenOutline();
    //     const chapter = allsubchapters.find((ch) => {
    //     return ch.id === chapterid;
    //     });
    //     if (chapter && chapter.substeps && chapter.substeps.length > 0) {
    //     if (direction === 1) {
    //         args["substepid"] = chapter.substeps[0].id;
    //     } else {
    //         args["substepid"] = chapter.substeps[chapter.substeps.length - 1].id;
    //     }
    //     }
    //     // navigation.push("Chapter", args);
    //     setSelectedChapters(args.chapterid,args.substepid);
    // };
    //     const selectChapter = (index) => {
    //         const payload = findChaptersForIndex(index);
    //         dispatch({ type: "SET_SELECTED_CHAPTER", payload: payload });
    //    //  const args = { step: index };
    //     // const allsubchapters = flattenOutline();
    //     // const chapter = allsubchapters.find((ch) => {
    //     // return ch.id === chapterid;
    //     // });
    //     // if (chapter && chapter.substeps && chapter.substeps.length > 0) {
    //     // if (direction === 1) {
    //     //     args["substepid"] = chapter.substeps[0].id;
    //     // } else {
    //     //     args["substepid"] = chapter.substeps[chapter.substeps.length - 1].id;
    //     // }
    //     // }
    //   //  navigation.navigate("Chapter", args);
    //    // setSelectedChapters(args.chapterid,args.substepid);
    // };
    // const showModal = () => {
    //     if (state.selectedIndex === null) {
    //    //  dispatch({ type: "SET_SELECTED_INDEX", payload: 0 });
    //       firstChapter();
    //     } else {
    //       selectChapter(state.selectedIndex );
    //     }
    //   };
    const closeModal = () => {
    navigation.navigate("Main", { screen: "Education" });
    // navigation.dispatch(StackActions.popToTop());
    };
//   const openChapter = (chapterid, direction = 1) => {
//     const index = findIndexForSubChapter(chapterid);
//     const payload = findChaptersForIndex(index);
//  //   dispatch({ type: "SET_SELECTED_INDEX", payload: index });
//     dispatch({ type: "SET_SELECTED_CHAPTER", payload: payload });
//     navigation.navigate("Chapter",{step:index});
// };
//   const firstChapter = () => {
//     selectChapter(0);
//   };
//   const setSelectedChapters = (chapterid, substepid) => {
//     // if (
//     //   state.outline &&
//     //   state.outline.chapters &&
//     //   state.outline.chapters.length > 0
//     // ) {
//       let doBreak = false;
//       const payload = {};
//       for (let i = 0; i < state.outline.chapters.length; i++) {
//         const chapter = state.outline.chapters[i];
//         for (let j = 0; j < chapter.subchapters.length; j++) {
//           const subchapter = chapter.subchapters[j];
//           if (subchapter.id === chapterid) {
//             payload.subchapter = subchapter;
//             payload.chapter = chapter;
//             let foundSubstep = false;
//             if (substepid !== "") {
//               for (let k = 0; k < subchapter.substeps.length; k++) {
//                 if (subchapter.substeps[k].id === substepid) {
//                   payload.substep = subchapter.substeps[k];
//                   foundSubstep = true;
//                   break;
//                 }
//               }
//             }
//             if (!foundSubstep && subchapter.substeps.length > 0) {
//               payload.substep = subchapter.substeps[0];
//             }

//             doBreak = true;
//             break;
//           }
//         }
//         if (doBreak) {
//           break;
//         }
//       }
//      // setPendingNavigation(false);
//       dispatch({ type: "SET_SELECTED_CHAPTER", payload: payload });
//     // } else {
//     //   setPendingNavigation(true);
//     // }
//   };
const lastIndex = (arr,cond) => {
    if (!arr.length) return -1;
    if (!cond) return arr.length-1;
  
    for (let i=arr.length-1; i>=0; --i) {
      if (cond(arr[i])) return i;
    }
  
    return -1;
  }
  const firstIndex = (arr,cond) => {
    if (!arr.length) return -1;
    if (!cond) return arr.length-1;
  
    for (let i=0; i<arr.length; i++) {
      if (cond(arr[i])) return i;
    }
  
    return -1;
  }
  const findChaptersForIndex = (index) => {
      //debugger;
      let payload = {chapter:null,subchapter:null,substep:null};
      if(state.substeps && state.substeps.length>0){
        payload = state.substeps[index];
      }
      return payload;
  
  };
  const findIndexForSubChapter= (subchapterid,first= true) => {
    let index = 0;
    if(state.substeps && state.substeps.length>0){
      
        if(first){
         index= firstIndex(state.substeps,(step)=>{return step.subchapter.id ===subchapterid});
        }else{
            index= lastIndex(state.substeps,(step)=>{return step.subchapter.id ===subchapterid});
        }
    }
    return index;

};


//   useEffect(() => {
//     if (
//       state.outline &&
//       state.outline.chapters &&
//       state.outline.chapters.length > 0 &&
//       pendingNavigation
//     ) {
//       setSelectedChapters(selectedSubchapterId, selectedSubstepId);
//     }
//   }, [state.outline]);

  return {
  //  selectChapter,
  //  firstChapter,
 //   showModal,
    closeModal,
 //   openChapter,
    findChaptersForIndex,
    findIndexForSubChapter
  };
};

export default ChapterNavigation;
