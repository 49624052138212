import React, { useState, useEffect, Component,useContext,useRef }  from 'react';
import { StyleSheet, Modal, View,Text,TouchableOpacity,Dimensions,Platform} from 'react-native';
import AuthService from "./services/auth.service";
import ContentService from "./services/content.service";
import {useGlobalState} from './services/store';
//import {Modal} from 'react-native-web';
import { Colors, Buttons } from "./styles";
//import * as Animatable from "react-native-animatable";
import {
  // Overlay,
  Icon,
} from "react-native-elements";

  const Root = props =>{
 //   const windowWidth = Dimensions.get('window').width;
  //  const windowHeight = Dimensions.get('window').height;
    const {state, dispatch} = useGlobalState();
   // const [modalContentVisible, setModalContentVisible] = useState(state.modalVisible);
    // const hideModal = ()=>{
    // // dispatch({type: 'SET_MODAL_CONTENT', payload: <VideoStep /> });
    //   dispatch({type: 'SET_MODAL_VISIBLE', payload: false });
    // };
    // const viewAnimation = useRef(null);
    // useEffect(() => {
    //   const Animation = async () => {
    //     if (state.modalVisible) {
    //       setModalContentVisible(true);
    //       if (viewAnimation.current) await viewAnimation.current.fadeInUpBig(600);
    //     } else {
    //       if (viewAnimation.current)
    //         await viewAnimation.current.fadeOutDownBig(600);
    //         setModalContentVisible(false);
    //     }
    //   };
  
    //   Animation();
    // }, [state.modalVisible, viewAnimation]);
const getFlattendedSubsteps = (outline) => {
  const flattenedSubchapters = [];
  for (let i = 0; i < outline.chapters.length; i++) {
    const chapter = outline.chapters[i];
    for (let j = 0; j < chapter.subchapters.length; j++) {
      const subchapter = chapter.subchapters[j];
           for (let k = 0; k < subchapter.substeps.length; k++) {   
                flattenedSubchapters.push({chapter:chapter,subchapter:subchapter,substep:subchapter.substeps[k]});      
           }
    }
  }
  return flattenedSubchapters;
}
    useEffect(() => {
       AuthService.getCurrentUser().then(
           (user)=>{
            dispatch({type: 'SET_USER', payload: user});
           },
           (error)=>{
            dispatch({type: 'SET_USER', payload: null});
           }

       );
       ContentService.getPublicContent().then(
          (content)=>{
          dispatch({type: 'SET_PUBLIC_CONTENT', payload: content});
          },
          (error)=>{
          dispatch({type: 'SET_PUBLIC_CONTENT', payload: []});
          }
        );
    }, []); 
    useEffect(() => {
      if(state.user!==null){
        ContentService.getProtectedContent().then(
          (content)=>{
          dispatch({type: 'SET_PROTECTED_CONTENT', payload: content});
          },
          (error)=>{
          dispatch({type: 'SET_PROTECTED_CONTENT', payload: []});
          }
        );
      }else{
        dispatch({type: 'SET_PROTECTED_CONTENT', payload: []});
      }
     
   }, [state.user]); 
   useEffect(() => {
    if(state.protectedcontent.outline){
     // debugger;
      const substeps = getFlattendedSubsteps(state.protectedcontent.outline[state.locale]);
      dispatch({type: 'SET_SUBSTEPS', payload: substeps});
      dispatch({type: 'SET_OUTLINE', payload: state.protectedcontent.outline[state.locale]});
    }else{
      dispatch({type: 'SET_OUTLINE', payload: []});
    }
    if(state.protectedcontent.outline){
     
      dispatch({type: 'SET_EMOTIONS', payload: state.protectedcontent.emotions[state.locale].baseemotions});
    }else{
      dispatch({type: 'SET_EMOTIONS', payload: []});
    }
 }, [state.protectedcontent,state.locale]); 

    return (
      <View style={styles.container}>{props.children}
         {/* <Animatable.View ref={viewAnimation}>
         {modalContentVisible && (<Modal
             style={styles.cover}
                visible={modalContentVisible}
                transparent={false}
              >
                <View style={styles.inneroverlay}>
                  {state.modalContent}
                  <TouchableOpacity
                  style={styles.minimize}
                    onPress={hideModal}
                  >
                    <Icon
                      color={Colors.white}
                      name="keyboard-arrow-down"
                      size={40}
                      type="material"
                    />
                  </TouchableOpacity>
                </View>
              </Modal>)} 
              </Animatable.View>  */}
      </View>);
}
const styles = StyleSheet.create({
    container: {
      flex: 1,
      flexDirection: "column",
      justifyContent: "flex-start",
      alignItems: "stretch",
      alignContent: "flex-start",
  //  alignItems: 'center',
   //   justifyContent: 'flex-start',
      // marginTop: 50,
      // padding: 50
    },
    // minimize:{
    //    position:"absolute",
    //    color:Colors.white,
    //    top:50,
    //    right:30
    // }, 
  // inneroverlay: {
  //   flex: 1,
  //   position:"relative"
  // },
    // container: {
    //   flex: 1,
    //   marginTop: 50,
    //   flexDirection: "row",
    //   justifyContent: "center",
    //   alignItems: "flex-start",
    // },
    // innercontainer: {
    //   flex: 1,
    //   flexDirection: "column",
    //   justifyContent: "flex-start",
    //   alignItems: "stretch",
    //   alignContent: "flex-start",
    //   borderWidth:1,
    //   borderStyle:"solid",
    //   borderColor: Colors.darkGrey,
    // },
    // cover: {
    //   height: Platform.OS === 'web' ? '100vh' : '100%',
    //   width: Platform.OS === 'web' ? '100vw' : '100%',
    //   //top:0,
    // //  left:0,
    //   //width:windowWidth,height:windowHeight,padding:0,margin:0}
    // }
  });
export default Root;
