import * as React from 'react';
import Svg, { Circle, Rect,Path,Line } from 'react-native-svg';

export const  Joy = (props) =>  {
    let size = 72;
    if(props.size){
        size = props.size;
    }
  return (

    <Svg  viewBox="0 0 63 62" fill="none" xmlns="http://www.w3.org/2000/svg">
<Circle cx="31.5113" cy="30.9502" r="30.9502" fill="#F6F3EE"/>
<Path d="M43.1938 38.6361C43.1938 38.6361 39.1979 44.0002 31.5864 44.0002C23.975 44.0002 19.7887 38.6361 19.7887 38.6361" stroke="#223B5C" strokeWidth="1.95475" strokeLinecap="round" strokeLinejoin="round"/>
<Circle cx="23" cy="27.1357" r="2" fill="#223B5C"/>
<Circle cx="41.772" cy="27.1357" r="2" fill="#223B5C"/>
</Svg>



  );
}
export const  Lust = (props) =>   {
    return (
      <Svg  viewBox="0 0 62 62" fill="none" xmlns="http://www.w3.org/2000/svg">
<Circle cx="30.9502" cy="30.9502" r="30.9502" fill="#F6F3EE"/>
<Path d="M42.21 30.0825C42.21 30.0825 38.2831 36.6724 30.8035 36.6724C23.3238 36.6724 19.21 30.0825 19.21 30.0825" stroke="#223B5C" strokeWidth="1.95475" strokeLinecap="round" strokeLinejoin="round"/>
<Circle cx="20.5282" cy="19.5281" r="2.28343" transform="rotate(-6.52617 20.5282 19.5281)" fill="#576980"/>
<Circle cx="36.9598" cy="19.5281" r="2.28343" transform="rotate(-6.52617 36.9598 19.5281)" fill="#576980"/>
<Circle cx="23.9598" cy="19.6107" r="2.28343" transform="rotate(-6.52617 23.9598 19.6107)" fill="#576980"/>
<Circle cx="40.3914" cy="19.6107" r="2.28343" transform="rotate(-6.52617 40.3914 19.6107)" fill="#576980"/>
<Path d="M22.5412 25.5044L25.9316 20.5825L18.9316 21.0825L22.5412 25.5044Z" fill="#576980"/>
<Path d="M38.9728 25.5044L42.3633 20.5825L35.3633 21.0825L38.9728 25.5044Z" fill="#576980"/>
</Svg>

              
    );
  }
  export const  Fear = (props) =>   {
    return (
      <Svg  viewBox="0 0 63 62" fill="none" xmlns="http://www.w3.org/2000/svg">
      <Circle cx="31.5108" cy="30.9502" r="30.9502" fill="#F6F3EE"/>
      <Line x1="28.0505" y1="23.3351" x2="21.3353" y2="27.2121" stroke="#223B5C" strokeWidth="1.95475" strokeLinecap="round" strokeLinejoin="round"/>
      <Line x1="53.0731" y1="27.2122" x2="46.3579" y2="23.3352" stroke="#223B5C" strokeWidth="1.95475" strokeLinecap="round" strokeLinejoin="round"/>
      <Path d="M26 40C26 40 29.9268 39 37.4065 39C44.8862 39 49 40 49 40" stroke="#223B5C" strokeWidth="1.95475" strokeLinecap="round" strokeLinejoin="round"/>
      <Circle cx="28.072" cy="29.8655" r="2.28343" fill="#223B5C"/>
      <Circle cx="46.844" cy="29.8655" r="2.28343" fill="#223B5C"/>
      </Svg>
      
    );
  }
  export const  Anger = (props) =>   {
    return (
      <Svg viewBox="0 0 63 62" fill="none" xmlns="http://www.w3.org/2000/svg">
      <Circle cx="31.5108" cy="30.9502" r="30.9502" fill="#F6F3EE"/>
      <Path d="M19.7886 37.5658C19.7886 37.5658 23.3886 31.5 31 31.5C38.6114 31.5 43.1937 37.5658 43.1937 37.5658" stroke="#223B5C" strokeWidth="1.95475" strokeLinecap="round" strokeLinejoin="round"/>
      <Line x1="23.6532" y1="16.3108" x2="17.3108" y2="12.3468" stroke="#223B5C" strokeWidth="1.95475" strokeLinecap="round" strokeLinejoin="round"/>
      <Line x1="44.7728" y1="12.3634" x2="39.3634" y2="16.2272" stroke="#223B5C" strokeWidth="1.95475" strokeLinecap="round" strokeLinejoin="round"/>
      <Circle cx="22.072" cy="21.7297" r="2.28343" fill="#223B5C"/>
      <Circle cx="40.844" cy="21.7297" r="2.28343" fill="#223B5C"/>
      </Svg>
      
    );
  }
  export const  Shame = (props) =>   {
    return (
      <Svg  viewBox="0 0 63 62" fill="none" xmlns="http://www.w3.org/2000/svg">
      <Circle cx="31.5108" cy="30.9502" r="30.9502" fill="#F6F3EE"/>
      <Path d="M20 46C20 46 23.9268 44.5 31.4065 44.5C38.8862 44.5 43 46 43 46" stroke="#223B5C" strokeWidth="1.95475" strokeLinecap="round" strokeLinejoin="round"/>
      <Line x1="23.5813" y1="26.293" x2="16.5146" y2="29.4845" stroke="#223B5C" strokeWidth="1.95475" strokeLinecap="round" strokeLinejoin="round"/>
      <Line x1="46.1821" y1="29.4842" x2="39.1151" y2="26.2933" stroke="#223B5C" strokeWidth="1.95475" strokeLinecap="round" strokeLinejoin="round"/>
      <Circle cx="22.072" cy="35.2756" r="2.28343" fill="#223B5C"/>
      <Circle cx="40.844" cy="35.2756" r="2.28343" fill="#223B5C"/>
      </Svg>
      
  
    );
  }
  export const  Sadness = (props) => {
    return (
      <Svg viewBox="0 0 63 62" fill="none" xmlns="http://www.w3.org/2000/svg">
      <Circle cx="31.5108" cy="30.9502" r="30.9502" fill="#F6F3EE"/>
      <Path d="M14.2109 45C14.2109 45 18.1378 43 25.6174 43C33.0971 43 37.2109 45 37.2109 45" stroke="#223B5C" strokeWidth="1.95475" strokeLinecap="round" strokeLinejoin="round"/>
      <Circle cx="16.2834" cy="33.2834" r="2.28343" fill="#223B5C"/>
      <Circle cx="35.0554" cy="33.2834" r="2.28343" fill="#223B5C"/>
      </Svg>
      
  
    );
  }
  export const  Distaste = (props) =>  {
    return (
      <Svg viewBox="0 0 63 62" fill="none" xmlns="http://www.w3.org/2000/svg">
      <Circle cx="31.793" cy="30.9502" r="30.9502" fill="#F6F3EE"/>
      <Path d="M14.0621 42.9254C14.0621 42.9254 17.0787 39.0112 24.3035 37.0754C31.5283 35.1395 36.2784 36.9725 36.2784 36.9725" stroke="#223B5C" strokeWidth="1.95475" strokeLinecap="round" strokeLinejoin="round"/>
      <Line x1="19.0138" y1="16.5655" x2="11.2598" y2="16.5655" stroke="#223B5C" strokeWidth="1.95475" strokeLinecap="round" strokeLinejoin="round"/>
      <Line x1="36.3327" y1="13.3351" x2="29.6175" y2="17.2121" stroke="#223B5C" strokeWidth="1.95475" strokeLinecap="round" strokeLinejoin="round"/>
      <Circle cx="15.3537" cy="23.8655" r="2.28343" fill="#223B5C"/>
      <Circle cx="34.1257" cy="23.8655" r="2.28343" fill="#223B5C"/>
      </Svg>
      
  
    );
  }

  export const EMOTIONMAP ={
    "joy":<Joy />,
    "lust":<Lust/>,
    "fear":<Fear/>,
    "anger":<Anger/>,
    "shame":<Shame/>,
    "sadness":<Sadness/>,
    "distaste":<Distaste/>,
  }