import React, { useState, useRef, useContext, useEffect } from "react";
import {
  View,
  Text,
  FlatList,
  TouchableOpacity,
  Platform,
  Dimensions,
  StyleSheet,
  TouchableWithoutFeedback,
} from "react-native";
import { useGlobalState } from "../services/store";
import ChapterNavigation from "../services/chapternavigation.service";
import { Colors, Buttons, Texts } from "../styles";
import StepProgress from "../components/StepProgress";
import i18n from "../services/translations";
import { useSpring, animated } from "react-spring/native";
import BottomChapterNav from "../components/BottomChapterNav";
import { Icon } from "react-native-elements";
import Renderer from "../helpers/renderer";
import { Link } from "../routing";
import { Video, AVPlaybackStatus } from "expo-av";
import Svg, { Circle, Path, Polygon, Rect } from "react-native-svg";
import {getTranslation} from '../helpers/functions';
const AnimatedView = animated(View);
const AnimatedTouchableOpacity = animated(TouchableOpacity);
const VideoStep = (props) => {
  const NAV_HIDE = 4000;
  const {
    //   chaptersAvailable,
    //   outline,
    //   selectedChapter,
    //   mainChapter,
    nextChapter,
    previousChapter,
    closeModal,
  } = ChapterNavigation();
  const { state, dispatch } = useGlobalState();
  const hideChapter = () => {
    closeModal();
  };
  const [slideupOpen, setSlideupOpen] = useState(false);
  const [navigationOpen, setNavigationOpen] = useState(true);
  const [playSize, setPlaySize] = useState(100);
  const [playCenter, setPlayCenter] = useState(50);
  const [playRadius, setPlayRadius] = useState(45);
  const [playIconSize, setPlayIconSize] = useState(30);
  const [playArc, setPlayArc] = useState(47.5);
  const [playStroke, setPlayStroke] = useState(5);
  const [playOffsetX, setPlayOffsetX] = useState(60);
  const [playOffsetY, setPlayOffsetY] = useState(60);
  const [slideSize, setTheSlideSize] = useState({ width: 0, height: 0 });
  const [videoSize, setVideoSize] = useState({ width: windowWidth, height: windowHeight });
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const windowWidth = Dimensions.get("window").width;
  const windowHeight = Dimensions.get("window").height;
  const [videoPlaying, setVideoPLaying] = useState(false);
  const [videoDone, setVideoDone] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [videoEverStarted, setVideoEverStarted] = useState(false);
  const [progressArc, setProgressArc] = useState("");
  const [videoProgress, setVideoProgress] = useState(0.0);
  const [chapterIcon, setChapterIcon] = useState(undefined);
  const showNavigation = () => {
    // debugger;
    if (!videoDone || props.substep.done_content === false) {
      setNavigationOpen(true);
      // setTimeout(() => {
      //   setNavigationOpen(false);
      //   if(!videoEverStarted){
      //     setTimeout(() => {
      //            if (isMounted && !videoEverStarted) setVideoEverStarted(true);
      //      }, 100);
      //     }
      // }, NAV_HIDE);
    }
  };

  useEffect(() => {
    let isMounted = true;
    if (navigationOpen) {
      setTimeout(() => {
        if (isMounted) setNavigationOpen(false);
       
      }, NAV_HIDE);
    }
    else if(!videoEverStarted){
      setTimeout(() => {
       // debugger;
             if (isMounted && !videoEverStarted) setVideoEverStarted(true);
       }, 100);
    }
    return () => {
      isMounted = false;
    };
  }, [navigationOpen]);

  useEffect(() => {
    let isMounted = true;
  //  debugger;
  if(!isLoading){
    if ( videoPlaying && state.selectedIndex!==props.stepindex ) {
      video.current?.stopAsync();
    }
    if(!videoPlaying && state.selectedIndex === props.stepindex){
      video.current?.playAsync();
    
    }
  }
    return () => {
      isMounted = false;
    };
  }, [state.selectedIndex,isLoading]);

  useEffect(() => {
    let isMounted = true;
  //  debugger;
 
    if ( videoPlaying && state.selectedIndex === props.stepindex && !state.isInEducation) {
      video.current?.pauseAsync();
    }
    if(!videoPlaying && state.selectedIndex === props.stepindex && state.isInEducation){
      video.current?.playAsync();
    
    }
    return () => {
      isMounted = false;
    };
  }, [state.isInEducation]);


  useEffect(() => {
    // debugger;
    let isMounted = true;
    if(props.subchapter.chapter_icon){
      const iconparts = props.subchapter.chapter_icon.split(":");
      if(iconparts && iconparts.length===2){
        setChapterIcon({type:iconparts[0],name:iconparts[1]});
      }
     }
    
    // setTimeout(() => {
    //   if (isMounted) setNavigationOpen(false);
    //   setTimeout(() => {
    //     if (isMounted && !videoEverStarted) setVideoEverStarted(true);
    //   }, 100);
    // }, NAV_HIDE);
    return () => {
      isMounted = false;
    };
  }, []);
  useEffect(() => {
    let isMounted = true;

    if (props.playStateChanged) {
      props.playStateChanged(videoDone);
    }
    return () => {
      isMounted = false;
    };
  }, [videoDone, videoEverStarted]);

 
  // const find_dimesions = (layout, setState) => {
  //   const { x, y, width, height } = layout;
  //   setState(height + 20);
  //   // const wantedWidth = width-50;
  //   // if(subchapter.image && wantedWidth>0 && wantedWidth!==viewWidth){
  //   //     setViewWidth(wantedWidth);
  //   // }
  // };

  // const navigationAnimationStyle = useSpring({
  //   height: navigationOpen || videoDone ? slideNavigationHeight : 0,
  //   //transform: `perspective(600px) rotateX(${flipped ? 180 : 0}deg)`,
  //   config: {
  //     mass: 1,
  //     tension: 700,
  //     friction: 20,
  //     clamp: true /*duration:300*/,
  //   },
  // });
  const headingAnimationStyle = useSpring({
    opacity: navigationOpen ? 1 : 0,
    //transform: `perspective(600px) rotateX(${flipped ? 180 : 0}deg)`,
    config: {
      mass: 1,
      tension: 700,
      friction: 20,
      clamp: true /*duration:300*/,
    },
  });
  const slideAnimationStyle = useSpring({
    // from:{

    // },
    // to:{

    // },
    // top: slideupOpen ? "2000pt" : "2000pt",
    translateY: slideupOpen ? -slideSize.height / 2 : windowHeight,
    // transform: slideupOpen
    //   ?
    //       `translate(-${slideSize.width / 2}, -${slideSize.height / 2})`

    //   : `translate(-${slideSize.width / 2},  ${windowHeight})`,
    //opacity: slideupOpen ? 1 : 0,
    //transform: `perspective(600px) rotateX(${flipped ? 180 : 0}deg)`,
    config: {
      mass: 1,
      tension: 700,
      friction: 20,
      clamp: true /*duration:300*/,
    },
  });
  const video = useRef(null);
  // const slide = useRef(null);

  const setPlayButtonOffset = (layout) => {
    const { x, y, width, height } = layout;
    setPlayOffsetY(height);
    setPlayOffsetX(width);
  };

  const setSlideSize = (layout) => {
    const { width, height } = layout;
    setTheSlideSize({ width: width, height: height });
  };
  // const setPlayButtonSize = (layout) => {
  //   const { x, y, width, height } = layout;
  //   //debugger;
  //   let targetSize = Math.round(width / 3);
  //   if (targetSize < 80) {
  //     targetSize = 80;
  //   }
  //   if (targetSize > 300) {
  //     targetSize = 300;
  //   }
  //   setPlaySize(targetSize);
  //   const center = Math.round(targetSize / 2);
  //   setPlayCenter(center);
  //   const stroke = Math.round(targetSize / 20);
  //   const radius = center - stroke;
  //   const arc = center - stroke / 2;
  //   const iconSize = Math.round(targetSize / 3);
  //   setPlayRadius(radius);
  //   setPlayStroke(stroke);
  //   setPlayArc(arc);
  //   setPlayIconSize(iconSize);
  // };

  const toggleVideo = () => {
    if (!videoPlaying && videoDone) {
      video.current?.replayAsync();
      setVideoDone(false);
    } else {
      videoPlaying ? video.current?.pauseAsync() : video.current?.playAsync();
    }
    showNavigation();
    // console.log(videoPlaying);
  };
  const replayVideo = () => {
    setVideoDone(false);
    setSlideupOpen(false);
    video.current?.replayAsync();

    // console.log(videoPlaying);
  };
  const setTheVideoSize = (videoProps)=>{
   
      const { width, height } = videoProps.naturalSize;
      let scale = 1;
   
   //   const heightScaled = height * (this.state.screenWidth / width);
    if(windowWidth<width){
      scale = windowWidth/width;
    }
    let scaledWidth = width*scale;
    let scaledHeight = height*scale
    if(windowHeight<scaledHeight){
      scale = windowHeight/height;
    }
    scaledWidth = width*scale;
    scaledHeight = height*scale

      setVideoSize({
        width:scaledWidth,
        height: scaledHeight,
      });
  };
  const pad = (text,size) =>{
    let s = ""+text;
    while (s.length < (size || 2)) {s = "0" + s;}
    return s;
  }
  const setVideoStatus = (status) => {
    // debugger;
    if (status.isLoaded && isLoading /*&& state.selectedIndex === props.stepindex*/) {
      setIsLoading(false);
      video.current?.setProgressUpdateIntervalAsync(1000);
    //  video.current?.playAsync();
    }

    if (status.isPlaying !== videoPlaying) {
      setVideoPLaying(status.isPlaying);
      if (
        status.positionMillis === status.durationMillis &&
        status.durationMillis > 0
      ) {
        setVideoDone(true);
        //setSlideupOpen(true);
        // setNavigationOpen(true);
        if (props.substep.done_content !== false) {
          setSlideupOpen(true);
        } else {
          showNavigation();
        }
      }
    }
    if (status.durationMillis > 0) {
      const millis = status.durationMillis-status.positionMillis;
      const minutes = Math.floor(millis/60000);
      const seconds =  pad(Math.floor((millis/1000)-minutes*60),2);

      const progress =`${minutes}:${seconds}`;

       setVideoProgress(progress);
      const progArc = describeArc(
        playCenter,
        playCenter,
        playArc,
        0,
        (status.positionMillis / status.durationMillis) * 360
      );
      setProgressArc(progArc);
    } else {
      setProgressArc("");
    }
  };

  const polarToCartesian = (centerX, centerY, radius, angleInDegrees) => {
    const angleInRadians = ((angleInDegrees - 90) * Math.PI) / 180.0;

    return {
      x: centerX + radius * Math.cos(angleInRadians),
      y: centerY + radius * Math.sin(angleInRadians),
    };
  };

  const describeArc = (x, y, radius, startAngle, endAngle) => {
    const start = polarToCartesian(x, y, radius, endAngle);
    const end = polarToCartesian(x, y, radius, startAngle);

    const largeArcFlag = endAngle - startAngle <= 180 ? "0" : "1";

    const d = [
      "M",
      start.x,
      start.y,
      "A",
      radius,
      radius,
      0,
      largeArcFlag,
      0,
      end.x,
      end.y,
    ].join(" ");

    return d;
  };
  const interpolationStyles = {
    playButton: {
      position: "absolute",
      top: "50%",
      left: "50%",
      zIndex: 40,
      transform: [
        { translateX: -playOffsetX / 2 },
        { translateY: -(playSize / 2 + (playOffsetY - playSize)) },
      ],
    },
    slideContainer: {
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: [
        { translateX: -slideSize.width / 2 },
        { translateY: slideAnimationStyle.translateY },
      ],
    },
    overlay: {
      position: "absolute",
      top: 0,
      left: 0,
      bottom: 0,
      right: 0,
      backgroundColor: Colors.darkerGrey,
      opacity: 0.3,
      zIndex: 5,
    },
  };
  const styles = {
    videoContainer: {
      flex: 1,
      flexDirection: "column",
      justifyContent: "space-between",
      position: "relative",
      alignItems: "stretch",
    },
    video: {
      alignSelf: "stretch",
      height: Platform.OS === "web" ? "100vh" : "100%",
    },
    replay: {
      color: Colors.white,
      marginTop: 10,
      fontSize: 12,
    },
    progressCircle: {},
    container: {
      flex: 1,
    },
    content: {
      flex: 2,
      flexGrow: 2,
      flexDirection: "row",
      justifyContent: "center",
      alignItems: "stretch",
      backgroundColor: Colors.darkGrey,
    },
    centerTitle: {
      fontSize: 20,
      lineHeight: 24,
      color: Colors.white,
      textAlign:"center",
      marginBottom: 40,
    },
    slideContentInner: {
      backgroundColor: Colors.offWhite,
      padding: 40,
      borderRadius: 12,
      flex: 1,
      position: "relative",
      flexDirection: "column",
      justifyContent: "flex-start",
      alignItems: "stretch",
      width:windowWidth-40,
     // width:400,
  //    minWidth:"90%"
    //  width: "100%",
    //  maxWidth: 1400,
    },
    slideBadge: {
      position: "absolute",
      width: 30,
      height: 30,
      top: -15,
      left: "50%",
      borderRadius: 40,
      zIndex: 40,
      flex: -1,
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      backgroundColor: Colors[props.subchapter.color],
      transform: [{ translateX: -15 }],
    },
    slideContent: {
      flex: 1,
      flexDirection: "column",
      justifyContent: "flex-start",
      alignItems: "stretch",
      padding: 20,
    },
    box: {
      borderStyle: "solid",
      borderWidth: 1,
      borderColor: Colors.white,
      borderRadius: 10,
      padding: 20,
    },
    innerContent: {
      flex: 1,
      flexDirection: "column",
      justifyContent: "space-between",
      alignItems: "stretch",
     // maxWidth: 1400,
    },
  };

  const gestureHandlers = {
    onStartShouldSetResponder: () => true,
    onMoveShouldSetResponder: () => true,
    // onResponderStart:() => {
    //   console.log('onResponderStart')
    // },
    onResponderGrant: () => {
      console.log('seeking');
      
      //beginning
     // this.setState({backgroundColor: 'green'})
    },
    onResponderMove: (event) => {
      console.log('onResponderMove');
      // if(event.nativeEvent){
      //   const angle = getAngle(event.nativeEvent.locationX,event.nativeEvent.locationY);

      //   video.current?.setPositionAsync(millis)
      //   console.log('angle:'+angle);
      // }
     
   
    },
    // onResponderEnd:() => {
    //   console.log('onResponderEnd');
    // },
    onResponderRelease: (event) => {
      console.log('resume play');
   //   const angle = getAngle(event.locationX,event.locationY);
   //   console.log('angle:'+angle);
         //ending
     // this.setState({backgroundColor: 'blue'})
    },
    // onResponderTerminationRequest: () => {
    //   console.log('onResponderTerminationRequest');
   
    //   return true;
    // },
    // onResponderTerminate: (evt) => {
    //   console.log('onResponderTerminate');
    // }
  };

  const getAngle = (pointx,pointy) =>{
    const dx = pointx - playCenter;
    // Minus to correct for coord re-mapping
    const dy = -(pointy - playCenter);

   const rads = Math.atan2(dy, dx);

    const result = rads * 180 / Math.PI; // Math.toDegrees(Math.atan2(dy, dx)); 
    return (result < 0) ? (360 + result) : result;
    // We need to map to coord system when 0 degree is at 3 O'clock, 270 at 12 O'clock
    // if (inRads < 0)
    //     inRads = Math.abs(inRads);
    // else
    //     inRads = 2 * Math.PI - inRads;

   // return Math.toDegrees(inRads);
};
  return (
    <View style={styles.container}>
      <TouchableWithoutFeedback onPress={showNavigation}>
        <View style={styles.content}>
          <View
            style={styles.innerContent}
            // onLayout={(event) => {
            //   setPlayButtonSize(event.nativeEvent.layout);
            // }}
          >
            <View style={styles.videoContainer}>
              <Video
                ref={video}
                style={styles.video}
                source={{
                  //props.substep.url,
                  uri:
                    // "https://workdrive.zohopublic.eu/embed/5ehzkf363568b7dae42cabacbd7c2b4ee27cf?toolbar=false"
                   // "https://appcm.insightgap.com/wp-content/uploads/2021/02/screens.mp4",
                  props.substep.url,
                }}
                // useNativeControls
                resizeMode="cover"
              //  onReadyForDisplay={(videoProps)=> setTheVideoSize(videoProps)}
                onPlaybackStatusUpdate={(status) => setVideoStatus(status)}
              />
              {(!videoPlaying || videoDone) && (
                <View style={interpolationStyles.overlay}></View>
              )}
              <AnimatedView
                style={{
                  ...interpolationStyles.playButton,
                  ...headingAnimationStyle,
                }}
              >
                <View
                  style={{
                    flex: -1,
                    flexDirection: "column",
                    justifyContent: "flex-start",
                    alignItems: "center",
                  }}
                  onLayout={(event) => {
                    setPlayButtonOffset(event.nativeEvent.layout);
                  }}
                >
                  {props.substep && (
                    <Text style={styles.centerTitle}>
                      {getTranslation(props.substep.title,state)}
                    </Text>
                  )}
                  <TouchableOpacity
                    onPress={toggleVideo}
                    style={{ position: "relative" }}
                  >
                       {!videoEverStarted &&
                      props.substep.before_content !== false && (
                        <View
                          style={{
                            flexDirection: "column",
                            justifyContent: "center",
                            alignItems: "center",
                          }}>
                        { props.substep.before_content.map((component, index) =>
                          Renderer(component, index)
                        )}
                        </View>
                      )}
                    {!videoEverStarted && props.substep.before_content === false &&
                      chapterIcon !== undefined && (
                        <View
                          style={{
                            height: playSize,
                            width: playSize,
                            flexDirection: "column",
                            justifyContent: "center",
                            alignItems: "center",
                            backgroundColor:
                              Colors[props.subchapter.color],
                          }}
                        >
                          <Icon
                            color={Colors.white}
                            name={chapterIcon.name}
                            size={playIconSize}
                            type={chapterIcon.type}
                          />
                        </View>
                      )}
                    {(videoEverStarted ||
                      chapterIcon === undefined && props.substep.before_content === false ) && (
                      <>
                      <View style={{flex:1,flexDirection:"column",justifyContent:"center",alignItems:"flex-start"}}> 
                        <View
                          {...gestureHandlers}
                          style={{
                            top: 0,
                            left: 0,
                            bottom: 0,
                            right: 0,

                            zIndex: 40,
                            position: "absolute",
                            flex: -1,
                            flexDirection: "column",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <Icon
                            color={Colors[props.subchapter.color]}
                            name={
                              videoDone
                                ? "repeat"
                                : videoPlaying
                                ? "pause"
                                : "play" //
                            }
                            size={playIconSize}
                            type="font-awesome"
                          />
                        </View>
                        <Svg
                          height={playSize}
                          width={playSize}
                          viewBox={`0 0 ${playSize} ${playSize}`}
                        >
                          <Path
                        
                            d={progressArc}
                            stroke={Colors[props.subchapter.color]}
                            strokeWidth={playStroke}
                            fill="none"
                          />
                          <Circle
                            style={styles.progressCircle}
                            cx={playCenter}
                            cy={playCenter}
                            r={playRadius}
                            fill={Colors.opaqueGrey}
                          />
                        </Svg>
                        </View>
                        <Text style={{fontSize:16,color:Colors.white,textAlign:"center"}}>{videoProgress}</Text>
                      </>
                    )}
                  </TouchableOpacity>
                </View>
              </AnimatedView>
            </View>
          </View>
        </View>
      </TouchableWithoutFeedback>
      <AnimatedView style={interpolationStyles.slideContainer}>
        <View
          style={styles.slideContent}
          onLayout={(event) => {
            setSlideSize(event.nativeEvent.layout);
          }}
        >
          {props.substep && (
            <Text style={styles.centerTitle}>
              {props.substep.title}
            </Text>
          )}
          <View style={styles.slideContentInner}>
            <View style={styles.slideBadge}>
              <Icon
                color={Colors.darkerGrey}
                name={"award"}
                size={20}
                type="feather"
              />
            </View>
            {props.substep.done_content !== false &&
              props.substep.done_content.length > 0 &&
              props.substep.done_content.map((component, index) =>
                Renderer(component, index)
              )}
          </View>
          <TouchableOpacity onPress={replayVideo}>
            <View style={{flex:1,flexDirection:"row",justifyContent:"center"}}>
            <Text style={styles.replay}>{i18n.t("buttons.playagain")}</Text>
            </View>
          </TouchableOpacity>
        </View>
      </AnimatedView>
    </View>
  );
};

export default VideoStep;
