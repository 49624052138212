import i18n from "../services/translations";

export const  arraysEqual = (a, b) => {
    if (a === b) return true;
    if (a == null || b == null) return false;
    if (a.length !== b.length) return false;
  
    for (var i = 0; i < a.length; ++i) {
      if (a[i] !== b[i]) return false;
    }
    return true;
  }

  export const getTranslation = (text,state)=>{
    // debugger;
    i18n.locale = state.locale;
     let regex = /{([^}]+)}/igm;
     let match;
     let newText = text;
     if(state.formdata.baseemotion!==null && state.formdata.baseemotion!==undefined ){
       while ((match = regex.exec(text))!==null) {
         //match[1]
        let translation = i18n.t(`emotions.${match[1]}.${state.formdata.baseemotion.id}`);
        if(translation!==undefined){
         newText = newText.replace(`{${match[1]}}`,translation);
        }
          // matches.push(match[1]);
        }
        return newText;
     }else{
       return text;
     }
   
   }