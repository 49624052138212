import React from 'react';
import { StyleSheet, Text, View, Dimensions } from 'react-native';

const {width, height} = Dimensions.get('window');

function Ingress (props) {
        return (
            <View  style={styles.ingress}>
                <Text style={styles.text}>{props.children}</Text>
            </View>)
}
const styles = StyleSheet.create({
        ingress: {
            fontWeight: "bold",
            margin:20
        },
        text:{
            fontWeight:"bold"
        }
});



export default Ingress;