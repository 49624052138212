import React, { useState, useRef, useContext, useEffect } from "react";
import {
  View,
  Text,
  FlatList,
  TouchableOpacity,
  Platform,
ScrollView,
  Dimensions,
  StyleSheet,
  TouchableWithoutFeedback,
} from "react-native";
import { useGlobalState } from "../services/store";
import ChapterNavigation from "../services/chapternavigation.service";
import { Colors, Buttons, Texts } from "../styles";
import { Joy,Lust,Fear,Anger,Shame,Sadness,Distaste,EMOTIONMAP} from "../helpers/basefeeling-assets";
import i18n from "../services/translations";
import BottomChapterNav from '../components/BottomChapterNav';
import {
  Icon,
} from "react-native-elements";

const BaseEmotionStep = (props) => {
 
    const NAV_HIDE = 4000;
    // const {
    //   closeModal,
    // } = ChapterNavigation();
    const { state, dispatch } = useGlobalState();
    const [chapterIcon, setChapterIcon] = useState(undefined);
    // const hideChapter = () => {
    //   closeModal();
    // };
    useEffect(() => {
    if(props.baseEmotionSelectedChanged){
      props.baseEmotionSelectedChanged(state.formdata?.baseemotion!==null &&state.formdata?.baseemotion!==undefined);
    }
    
    }, [state.formdata?.baseemotion]);

    useEffect(() => {
      if(props.subchapter.chapter_icon){
        const iconparts = props.subchapter.chapter_icon.split(":");
        if(iconparts && iconparts.length===2){
          setChapterIcon({type:iconparts[0],name:iconparts[1]});
        }
       }
      
    }, []);

    const selectBaseEmotion = (baseemotion)=>{
        dispatch({type: 'SET_FORMDATA', payload: {baseemotion:baseemotion,propertyname:"baseemotion"} });
    };
    
    const getIsSelected = (id,defaultstyle) =>{
      if(state.formdata.baseemotion?.id==id){
        return {...defaultstyle,backgroundColor:Colors.salmon}
      }else{
        return defaultstyle;
      }
    }
       // nextChapter();
    const  emotiontext ={
      ...Texts.bright,
      marginTop:10,
      borderColor:Colors.white,
      borderRadius:25,
      padding:10,
      borderWidth:1
      //textAlign:"center",
    };
 // const [isLoggedIn, setIsLoggedIn] = useState(false);
 // const [isLoading, setIsLoading] = useState(true);
  return (

    <View style={styles.container}>
    {/* <View style={styles.topHeading}>
      <Text style={styles.topHeadingText}>
        {i18n.t("educationstep.heading")}
      </Text>
      <TouchableOpacity style={styles.minimize} onPress={hideChapter}>
      <Icon
        color={Colors.white}
        name="keyboard-arrow-down"
        size={40}
        type="material"
      />
    </TouchableOpacity>
    </View> */}
  
    {/* onLayout={this.handleLayout}  */}
        <View style={{flex:1}}  > 
        <ScrollView  contentContainerStyle={styles.content} >
            {/* <View style={{flex:1}}> */}
        <View style={styles.innerContent} >
        <View style={styles.header}>
        {chapterIcon !== undefined && (
                        <View
                          style={{
                            height: 51,
                            width: 51,
                            marginRight:20,
                            flexDirection: "column",
                            justifyContent: "center",
                            alignItems: "center",
                            backgroundColor:
                              Colors[props.subchapter.color],
                          }}
                        >
                          <Icon
                            color={Colors.white}
                            name={chapterIcon.name}
                            size={24}
                            type={chapterIcon.type}
                          />
                        </View>
        )}
        <Text style={styles.headerText}>{props.substep.title}</Text>
        </View>
{/* 
          <View style={{width:200,height:200, backgroundColor:Colors.offWhite,borderRadius:150}}>
        { state.formdata.baseemotion&&  EMOTIONMAP[state.formdata.baseemotion.id]}
        </View> */}
        {/* <Icon     color={Colors.white}
                    name="sentiment-dissatisfied"
                    size={120}
                    type="material" /> */}
                 
            {props.substep.emotion_select_text && <Text style={styles.emotionselecttext}>{props.substep.emotion_select_text}</Text>}
            <View style={styles.emotions}>
                {state.baseemotions.length>0 && state.baseemotions.map((baseemotion,index)=>(
                  
                    <TouchableOpacity key={baseemotion.id}  style={styles.emotion} onPress={() => {selectBaseEmotion(baseemotion);}}>  
                    <View  style={styles.emotionicon}>
                    {/* <Icon     color={Colors.white}
               
                    name="sentiment-dissatisfied"
                    size={70}
                    type="material" />
                   */}
                    { EMOTIONMAP[baseemotion.id]}
                    </View>
                    <Text style={getIsSelected(baseemotion.id,emotiontext)}>{baseemotion.title}</Text>
                    </TouchableOpacity>
                ))}
                </View>
        </View>
        {/* </View> */}
        </ScrollView>
         </View> 
   
{/* 
<BottomChapterNav  />      */}
        
  </View>
        
  );
};
var styles = StyleSheet.create({
      container: {
        flex: 1,
      
        backgroundColor: Colors.darkerBlue,
         flexDirection: "column",
         justifyContent: "space-between",
         alignItems: "stretch",
         padding:20,
        // position: "relative",
      },
      content: {
      // flex: 1,
        flexGrow: 1,
        flexDirection: "row",
        justifyContent: "center",
        alignItems:"flex-start",
        marginTop:60,
        paddingTop:30,
        paddingBottom:30,
       // alignItems: "flex-start",
      //  backgroundColor: Colors.green,
      },
      innerContent: {
        flex: 1,
        flexDirection: "column",
        justifyContent: "flex-start",
        alignItems:"stretch",
       // backgroundColor:Colors.pink,
       // flexWrap:"wrap",
       // alignItems: "flex-start",
       paddingBottom:150,
      //  maxWidth: 900,
      },
      header:{
        flex: 1,
        flexDirection: "row",
        justifyContent: "flex-start",
        alignItems:"center",
      },
      // header:{
      //   color:Colors.white,
      //   fontSize:25,
      //   fontWeight:"600",
      //   lineHeight:25,
      //   width:"100%",
      //   textAlign:"left"
      // },
      headerText:{
        flex:1,
        fontSize:21,
        lineHeight:25,
        color:Colors.white
      }, 
      
      emotions:{
        flex: 1,
        flexGrow:0,
        flexDirection: "row",
        flexWrap:"wrap",
        alignItems:"center",
        justifyContent: "center",
       // marginTop:20,
      },
      emotion:{
          // minWidth:40,
          // height:40,
          // padding:10,
          marginTop:10,
          marginBottom:20,
          marginLeft:10,
          marginRight:10,
          flex: -1,
          flexDirection: "column",
         // flexWrap:"wrap",
          alignItems:"center",
          justifyContent: "flex-start"
       // backgroundColor:Colors.salmon

      },
      emotionicon:{
        // minWidth:120,
         height:80,
         width:80,
         flexDirection: "column",
         alignItems:"center",
         justifyContent: "center",
        // padding:10,
        // margin:20,
     // backgroundColor:Colors.pink

    },
 
      // emotiontext: {
      //   ...Texts.bright,
      //   marginTop:10,
      //   borderColor:Colors.white,
      //   borderRadius:25,
      //   padding:10,
      //   borderWidth:1
      //   //textAlign:"center",
      // },
      emotionselecttext: {
        ...Texts.bright,
        marginTop:40,
        marginBottom:40,
        //textAlign:"center",
      },
      horizontalSpace: {
        flex: 1,
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
      },
  });
export default BaseEmotionStep;