import React, { useState, useRef, useContext, useEffect } from "react";
import {
  View,
  Text,
  TextInput,
  FlatList,
  TouchableOpacity,
  Platform,
  ScrollView,
  Dimensions,
  StyleSheet,
  TouchableWithoutFeedback,
  ActivityIndicator,
} from "react-native";
import { useGlobalState } from "../services/store";
import ChapterNavigation from "../services/chapternavigation.service";
import { Colors, Buttons, Texts, Inputs } from "../styles";
import i18n from "../services/translations";
import BottomChapterNav from "../components/BottomChapterNav";
import { Input } from "react-native-elements";
import { Icon } from "react-native-elements";
import Renderer from "../helpers/renderer";
import { getActiveChildNavigationOptions } from "react-navigation";
const TextFormStep = (props) => {
  const NAV_HIDE = 4000;
  const {
    //   chaptersAvailable,
    //   outline,
    //   selectedChapter,
    //   mainChapter,
    nextChapter,
    previousChapter,
    closeModal,
  } = ChapterNavigation();
  const { state, dispatch } = useGlobalState();
  const hideChapter = () => {
    closeModal();
  };
  const [chapterIcon, setChapterIcon] = useState(undefined);
  // const find_dimesions = (layout, setState) => {
  //   const { x, y, width, height } = layout;
  //   setState(height + 20);
  // };
  // const [scrollViewHeight,setScrollViewHeight] = useState(0);
  // const [scrollViewWidth,setScrollViewWidth] = useState(0);
  useEffect(() => {
    //  debugger;
    //   setTimeout(() => {
    //       setNavigationOpen(false);
    //   }, NAV_HIDE);
  }, [props.substep]);
  useEffect(() => {
    if (props.subchapter.chapter_icon) {
      const iconparts = props.subchapter.chapter_icon.split(":");
      if (iconparts && iconparts.length === 2) {
        setChapterIcon({ type: iconparts[0], name: iconparts[1] });
      }
    }
  }, []);
  // const handleLayout = e =>{
  //     setScrollViewWidth(e.nativeEvent.layout.width);
  //     setScrollViewHeight(e.nativeEvent.layout.height);
  // };
  // const selectEmotion = (emotion)=>{
  //     dispatch({type: 'SET_FORMDATA', payload: {emotion:emotion,propertyname:"emotion"} });
  //     nextChapter();
  // }
  // const deselectBaseEmotion = ()=>{
  //     dispatch({type: 'SET_FORMDATA', payload: {baseemotion:null,propertyname:"baseemotion"} });
  //     previousChapter();
  // }
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  //   const windowWidth = Dimensions.get("window").width + "px";
  //   const windowHeight = Dimensions.get("window").height + "px";
  const [isLoading, setIsLoading] = useState(true);
  const [helpOpen, setHelpOpen] = useState(false);
  const onChangeText = (value, field) => {
    const payload = { propertyname: field };
    payload[field] = value;
    dispatch({ type: "SET_FORMDATA", payload: payload });

    if (props.textChanged) {
      props.textChanged(value && value.length > 0);
    }
  };
  const toggleHelp = () => {
    setHelpOpen(!helpOpen);
  };

  return (
    <View style={styles.container}>
      <View style={{ flex: 1 }}>
        <ScrollView contentContainerStyle={styles.content}>
          {/* <View style={{flex:1}}> */}
          <View style={styles.innerContent}>
            {/* {viewContent.map((component, index) => Renderer(component,index))}
            <Text>hej</Text> */}
            <View style={styles.topContent}>
              <View style={styles.header}>
                {chapterIcon !== undefined && (
                  <View
                    style={{
                      height: 51,
                      width: 51,
                      marginRight: 20,
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                      backgroundColor: Colors[props.subchapter.color],
                    }}
                  >
                    <Icon
                      color={Colors.white}
                      name={chapterIcon.name}
                      size={24}
                      type={chapterIcon.type}
                    />
                  </View>
                )}
                <Text style={styles.headerText}>{props.substep.title}</Text>
              </View>

              {props.substep &&
                props.substep.view_content &&
                props.substep.view_content.map((component, index) =>
                  Renderer(component, index, { color: Colors.white})
                )}
              {props.substep.help_content && (
                <View style={styles.accordion}>
                  <TouchableOpacity onPress={toggleHelp} style={styles.accordionHeader}>
                    <Icon
                      color={Colors.white}
                      name={
                        helpOpen
                          ? "keyboard-arrow-down"
                          : "keyboard-arrow-right"
                      }
                      size={24}
                      type="material"
                    />
                    <Text style={styles.accordionHeaderText}>{i18n.t("buttons.showmore")}</Text>
                  </TouchableOpacity>
                  {helpOpen &&
                    props.substep.help_content.map((component, index) =>
                      Renderer(component, index, { color: Colors.white })
                    )}
                </View>
              )}
            </View>
            <View></View>
            {props.substep &&
              props.substep.textplaceholders &&
              props.substep.textplaceholders.map((textplaceholder, index) => (
                <TextInput
                  key={`textinput_${index}`}
                  //  inputContainerStyle={}
                  //  secureTextEntry={true}
                  placeholder={textplaceholder.placeholder}
                  multiline={true}
                  // numberOfLines={3}
                  style={{
                    ...Inputs.inputText,
                    height: 100,
                    textAlignVertical: "top",
                  }}
                  //   label={props.isRegisterPage?i18n.t('labels.registerpassword'):i18n.t('labels.password') }
                  onChangeText={(e) => {
                    onChangeText(e, textplaceholder.data_field);
                  }}
                  labelStyle={Inputs.labelStyle}
                />
              ))}
            {/* <TouchableOpacity style={{width:'100%'}} onPress={nextChapter}> 
            <Text style={Buttons.smallRounded} >
                {i18n.t('buttons.continue') } 
            </Text>
            </TouchableOpacity > */}
          </View>
          {/* </View> */}
        </ScrollView>
      </View>

      {/* <BottomChapterNav  />      */}
    </View>
  );
};
var styles = StyleSheet.create({
  container: {
    flex: 1,
    //height: Platform.OS === 'web' ? '100vh' : '100%',
    backgroundColor: Colors.darkerBlue,
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "stretch",
    padding:20,
    // position: "relative",
  },
  content: {
    // flex: 1,
    flexGrow: 1,
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "stretch",
    paddingTop: 30,
    marginTop: 60,
    paddingBottom: 100,
    // alignItems: "flex-start",
    //  backgroundColor: Colors.green,
  },
  header: {
  //  flex: -1,
   // height: 70,
    // flexGrow:0,
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
    // backgroundColor:Colors.orange
  },
  headerText: {
    fontSize: 21,
    lineHeight: 25,
    color: Colors.white,
  },
  topContent: {
    flex: -1,
   // flexGrow: 0,
    flexDirection: "column",
    justifyContent: "flex-start",
    // alignSelf: "",
    alignItems: "stretch",
    // backgroundColor:Colors.orange
  },
  accordion: {
      flex: 1,

    //flexGrow:0,
     flexDirection: "column",
     justifyContent: "flex-start",
     alignItems:"stretch",
   // marginTop:20
  //  backgroundColor: Colors.green,
  },
  accordionHeader:{
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems:"flex-start",
   // backgroundColor:Colors.green
  },
  accordionHeaderText:{
    color:Colors.white,
    lineHeight:24
  },
  innerContent: {
    flex: 1,
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "stretch",
    //  backgroundColor:Colors.pink,
    // flexWrap:"wrap",
    // alignItems: "flex-start",
    // paddingBottom:150,
   // maxWidth: 900,
  },
  emotions: {
    flex: 1,
    flexGrow: 0,
    flexDirection: "row",
    flexWrap: "wrap",
    alignItems: "center",
    justifyContent: "center",
  },
  emotion: {
    minWidth: 120,
    height: 120,
    padding: 20,
    margin: 20,
    // backgroundColor:Colors.salmon
  },
  emotionicon: {

    backgroundColor: Colors.salmon,
  },
  emotiontext: {
    ...Texts.bright,
    textAlign: "center",
  },
  horizontalSpace: {
    flex: 1,
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
});
export default TextFormStep;
