import {Colors} from './colors';

export const  labelStyle ={textTransform:'uppercase',fontSize:18};
export const  inputText ={
    borderWidth:1,
    borderStyle:"solid",
    borderColor: Colors.darkerGrey,
    backgroundColor:Colors.offWhite,
borderRadius:5,
paddingLeft:10,
paddingRight:10,
marginLeft:0,
};