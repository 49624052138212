import React, { useState, useRef,useContext, useEffect } from "react";
import {StyleSheet, View,Text ,Button,TextInput, TouchableOpacity,ActivityIndicator} from 'react-native';
import Icon from 'react-native-vector-icons/FontAwesome';
import { Input } from 'react-native-elements';
//import { TextInput } from 'react-native-paper';
// import Form from "react-validation/build/form";
// import Input from "react-validation/build/input";
// import CheckButton from "react-validation/build/button";
import AuthService from "../services/auth.service";
import {useGlobalState} from '../services/store';
import Base64 from '../helpers/base64';
import i18n from '../services/translations';
import { Typography, Colors, Spacing, Buttons,Inputs } from '../styles'
//import {useNavigation} from '@react-navigation/native';
// const required = (value) => {
//   if (!value) {
//     return (
//       <div className="alert alert-danger" role="alert">
//         This field is required!
//       </div>
//     );
//   }
// };

const Login = (props) => {
 // const navigation = useNavigation();
  // const form = useRef();
  // const checkBtn = useRef();
  const {state, dispatch} = useGlobalState();
  //const [state, dispatch] = useContext(Context);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [customauth, setCustomAuth] = useState("");
  const [loading, setLoading] = useState(false);
 // const [message, setMessage] = useState("");
  const [token,setToken] = useState("");
 // const [isRegistering,setIsRegistering] = useState(false);
  const [customerName,setCustomerName] = useState("");

  const onChangeUsername = (e) => {
    const username = e;/// e.target.value;
    setUsername(username);
  };
 
   const getCustomerName = (token) =>{
     if(token && token.length>0){
      const decodedToken = Base64.decode(token);
      const parts = decodedToken.split(";");
      if(parts.length==3){
        return parts[2];
      }else{
        return "";
      }
     }else{
       return "";
     }
   }

   //const customerName = getCustomerName(props.registrationToken);

   useEffect(() => {
    if(props.registrationToken && props.registrationToken.length>0){
      const custname = getCustomerName(props.registrationToken);
      if(custname.length>0){
     //   setIsRegistering(true);
        setCustomerName(custname);
        setToken(props.registrationToken);
      }
    }
}, [props.registrationToken]);
  const onChangePassword = (e) => {
    const password =e;///  e.target.value;
    setPassword(password);
  };
  const onChangeCode = (e) => {
  //  const password =e;///  e.target.value;
    setCustomAuth(e);
  };

  const handleLogin = (e) => {
    e.preventDefault();

  //  setMessage("");
    setLoading(true);

   // form.current.validateAll();

   if (props.isRegisterPage) {
      AuthService.register(username, password,token).then(
        (user) => {
     
         setLoading(false);
         if(props.isRegisterPage && props.loginCallback!==undefined){
          props.loginCallback();
         }
         dispatch({type: 'SET_USER', payload: user});//(user);//
        },
        (error) => {
          setLoading(false);
        }
      );
      }
      else{
        AuthService.login(username, password).then(
          (user) => {
         
           setLoading(false);
           if(props.isRegisterPage && props.loginCallback!==undefined){
            props.loginCallback();
           }
           dispatch({type: 'SET_USER', payload: user});//(user);//
          },
          (error) => {
            setLoading(false);
          }
        );
        }
      
  };

  return (
        <View style={styles.container}>
         {/* {isRegistering && <Text>registrerar för {customerName}</Text> } */}

        {/* <Text style={styles.logo}>HeyAPP</Text> */}
        
          <Input  
            inputContainerStyle={Inputs.inputText}
            type="email"
            placeholder={i18n.t('inputs.email')} 
            autoCompleteType="email"
            value={username}
            label={i18n.t('labels.email')}
            labelStyle={Inputs.labelStyle}
            onChangeText={onChangeUsername}
            leftIcon={
              <Icon
                name='envelope'
                size={24}
              
              />}
            />
    

     
        <Input 
            inputContainerStyle={Inputs.inputText}
            secureTextEntry={true} 
            placeholder={i18n.t('inputs.password')} 
            label={props.isRegisterPage?i18n.t('labels.registerpassword'):i18n.t('labels.password') } 
            onChangeText={onChangePassword}
            labelStyle={Inputs.labelStyle}
              leftIcon={
        <Icon
          name='lock'
          size={24}
        
        />
  }
 />
       {props.isRegisterPage && 
          <Input  
            secureTextEntry
            inputContainerStyle={Inputs.inputText}
            value={token}
            label={i18n.t('labels.customercode')}
            labelStyle={Inputs.labelStyle}
            onChangeText={onChangeCode}
            leftIcon={
      <Icon
        name='lock'
        size={24}
      
      />}
            placeholder={i18n.t('inputs.customercode')} 
            />
       }
  
        <TouchableOpacity style={{width:'100%'}} onPress={handleLogin}> 
        <Text style={Buttons.smallRounded} >
            {props.isRegisterPage?i18n.t('buttons.register'):i18n.t('buttons.login') } 
        </Text>
        <ActivityIndicator animating={loading} />
        </TouchableOpacity >
      </View>
    
  );
};
const styles = StyleSheet.create({
    container: {
      flex: 1,
      height:400,
    //  marginTop:20,
    //   height:'100%',
    //   backgroundColor: '#003f5c',
    alignContent:"flex-start",
      alignItems: 'center',
      justifyContent: 'center',
    //  overflow:'visible',
    },
    logo:{
      fontWeight:"bold",
      fontSize:50,
      color:"#fb5b5a",
      marginBottom:40
    },
    labelStyle:{textTransform:'uppercase',fontSize:18},
    inputText:{
      borderWidth:1,
      borderStyle:"solid",
      borderColor: "#ccc",
   borderRadius:5,
   paddingLeft:10,
   paddingRight:10,
      // height:50,
      // color:"white"
    },
    forgot:{
      color:"white",
      fontSize:11
    },
    loginBtn:{
     // width:"80%",
      backgroundColor:"#88aaf7",
      borderRadius:10,
      width:'80%',
      marginLeft:'auto',
      marginRight:'auto',
      textAlign:'center',
     // height:50,
     padding:'10px',
      alignItems:"center",
      justifyContent:"center",
      //marginTop:40,
      marginBottom:10
    },
    loginText:{
      color:"white"
    }
  });

//   Login['navigationOptions'] = screenProps => ({
//     title: 'Login'
// })
// Login['path'] = "login";
export default Login;



          {/* <TextInput  
            secureTextEntry
            style={Inputs.inputText}
            type="password"
            autoCorrect="false"
            autoCompleteType="password"
            placeholder={i18n.t('inputs.password')} 
            label={i18n.t('labels.password')}
            placeholderTextColor="#003f5c"
            onChangeText={onChangePassword}/> */}
  /* <TouchableOpacity>
          <Text style={styles.forgot}>Forgot Password?</Text>
        </TouchableOpacity>
        <TouchableOpacity style={styles.loginBtn} on>
          <Text style={styles.loginText}>LOGIN</Text>
        </TouchableOpacity>
        <TouchableOpacity>
          <Text style={styles.loginText}>Signup</Text>
        </TouchableOpacity> 
    // <div className="col-md-12">
    //   <div className="card card-container">
    //     <img
    //       src="//ssl.gstatic.com/accounts/ui/avatar_2x.png"
    //       alt="profile-img"
    //       className="profile-img-card"
    //     />

    //     <Form onSubmit={handleLogin} ref={form}>
    //       <div className="form-group">
    //         <label htmlFor="username">Username</label>
    //         <Input
    //           type="text"
    //           className="form-control"
    //           name="username"
    //           value={username}
    //           onChange={onChangeUsername}
    //           validations={[required]}
    //         />
    //       </div>

    //       <div className="form-group">
    //         <label htmlFor="password">Password</label>
    //         <Input
    //           type="password"
    //           className="form-control"
    //           name="password"
    //           value={password}
    //           onChange={onChangePassword}
    //           validations={[required]}
    //         />
    //       </div>

    //       <div className="form-group">
    //         <button className="btn btn-primary btn-block" disabled={loading}>
    //           {loading && (
    //             <span className="spinner-border spinner-border-sm"></span>
    //           )}
    //           <span>Login</span>
    //         </button>
    //       </div>

    //       {message && (
    //         <div className="form-group">
    //           <div className="alert alert-danger" role="alert">
    //             {message}
    //           </div>
    //         </div>
    //       )}
    //       <CheckButton style={{ display: "none" }} ref={checkBtn} />
    //     </Form>
    //   </div>
    // </div>*/