import React, { useState, useRef, useContext, useEffect } from "react";
import {
  View,
  Text,
  FlatList,
  TouchableOpacity,
  Platform,
  StyleSheet,
  TouchableWithoutFeedback,
  ActivityIndicator
} from "react-native";
 import {
   useRoute,
   useNavigation,
 } from "@react-navigation/native";
import { Colors, Buttons, Texts } from "../styles";
import { useGlobalState } from "../services/store";
import ChapterNavigation from "../services/chapternavigation.service";
import VideoStep from "../components/VideoStep";
import EmotionStep from "../components/EmotionStep";
import BaseEmotionStep from "../components/BaseEmotionStep";
import TextFormStep from "../components/TextFormStep";
import RadioFormStep from "../components/RadioFormStep";
import RangeStep from "../components/RangeStep";
import ResultStep from "../components/ResultStep";
import BottomChapterNav from "../components/BottomChapterNav";
import i18n from "../services/translations";
import { LinearGradient } from "expo-linear-gradient";
//import Swiper from "react-native-web-swiper";
import { Swiper } from '../components/Swiper';
//import { useSpring, animated } from "react-spring/native";///renderprops-native
import {
  // Overlay,
  Icon,
} from "react-native-elements";

// const AnimatedView = animated(View);
// const AnimatedTouchableOpacity = animated(TouchableOpacity);

const EducationStep = (props) => {
  const route = useRoute();
  const navigation = useNavigation();
  const { state, dispatch } = useGlobalState();
  const {
    selectChapter,
    firstChapter,
    showModal,
    closeModal,
    findChaptersForIndex
  } = ChapterNavigation();
  const [headerHeight, setHeaderHeight] = useState(100);
  const [stepDone, setStepDone] = useState(false);
  
  useEffect(() => {
    setStepDone(false);
   swiperRef.current.refresh();
   let step = undefined;//state.selectedIndex;

   if (route.name === "Chapter") {

    if(!state.isInEducation){
      dispatch({ type: "SET_ISINEDUCATION", payload: true });
    }
    const params = route.params;
  //debugger;
    if (params !== undefined) {
      if (params.step !== undefined ) {
        step = +params.step;
       // if(step>-1 && step!==state.selectedIndex){
      //    goto(step ,true);
       // dispatch({ type: "SET_SELECTED_INDEX", payload: step });
      //  }
       
       
      }
    }
  }

    // if(state.substeps.length>0 ){
     //debugger;
    //   // const index = getIndexOfSubstep(state.selectedSubstep.id);
      if(step !==undefined && step!== state.selectedIndex && state.substeps && state.substeps.length>0){
       // goto(0,true);
     // }else{
        goto(step,true);
      }
       
    // }
  }, [state.substeps,route.params?.step]);


  // useEffect(() => {
  //   setStepDone(false);
  // }, [state.selectedIndex]);
  // useEffect(() => {
  //   //debugger;
  
  // }, []);
//   useEffect(() => {
//     if(state.selectedSubstep){
//       const index = getIndexOfSubstep(state.selectedSubstep.id);
//       goto(index,false);
//      }
//  }, [state.substeps]);
//   useEffect(() => {
//     if (route.name === "Chapter") {
//       const params = route.params;
//  //  debugger;
//       if (params !== undefined) {
//         if (params.step !== undefined ) {
//           const step = +params.step;
//           if(step>-1 && step!==state.selectedIndex){
//             goto(step ,true);
//          // dispatch({ type: "SET_SELECTED_INDEX", payload: step });
//           }
         
         
//         }
//       }
//     }
//   }, [route.params?.step]);

  const find_dimesions = (layout, setState) => {
    const { x, y, width, height } = layout;
    setState(height);
    // const wantedWidth = width-50;
    // if(subchapter.image && wantedWidth>0 && wantedWidth!==viewWidth){
    //     setViewWidth(wantedWidth);
    // }
  };
  const gotoNext = () =>{
  // swiperRef.current.goToNext();
 const index = swiperRef.current.getActiveIndex() +1;

   goto(index,true);
  }
  const goto = (index,updatestate=false) =>{
  
    swiperRef.current.goTo(index);
    // let step = +route.params?.step;
    // if(step!==index){
    //   navigation.navigate("Chapter", {step:index});
    // }
    if(updatestate){
      updateByIndex(index);
    }
  }
  const gotoPrevious = () =>{
  // swiperRef.current.goToPrev();
   const index = swiperRef.current.getActiveIndex() -1;

   goto(index,true);
  //  updateByIndex();
   
  }
  const updateByIndex = (index) =>{
   // const index  = swiperRef.current.getActiveIndex();
   //debugger;
    const payload = findChaptersForIndex(index);
    dispatch({ type: "SET_SELECTED_INDEX", payload: index });
    if(payload){
      
    dispatch({ type: "SET_SELECTED_CHAPTER", payload: payload });
    }
    let step = +route.params?.step;
     if(step!==index){
       navigation.navigate("Chapter", {step:index});
     }
  }
  const setHeaderState = () => {};
  const swiperRef = useRef(null);
  const stepDoneChanged = (isDone) => {
    setStepDone(isDone);
  };
  const close = () =>{
    dispatch({ type: "SET_ISINEDUCATION", payload: false });
    closeModal();
  }
  const SelectStepType = (chapter,subchapter,substep,index) => {
    //   debugger;
    // if(index !==state.selectedIndex){
    //   return < ActivityIndicator key={`step_${index}`} />;
    //  // <InactiveStep key={`step_${index}`} chapter={chapter} subchapter={subchapter} substep={substep} stepindex={index}  />
    // }
    switch (substep.type) {
      case "video":
        return (
        //  <View><Text>quesadilla</Text></View>
        <VideoStep key={`step_${index}`} chapter={chapter} subchapter={subchapter} substep={substep} stepindex={index} playStateChanged={stepDoneChanged} />
        );
        break;
      case "baseemotion":
        return  <BaseEmotionStep key={`step_${index}`} chapter={chapter} subchapter={subchapter} substep={substep} stepindex={index}  baseEmotionSelectedChanged={stepDoneChanged} />
        break;
      case "emotion":
        return  <EmotionStep key={`step_${index}`} chapter={chapter} subchapter={subchapter} substep={substep}  stepindex={index}  emotionSelectedChanged={stepDoneChanged} />
        break;
      case "freetext":
        return  <TextFormStep key={`step_${index}`} chapter={chapter} subchapter={subchapter} substep={substep} stepindex={index}  textChanged={stepDoneChanged} />
        break;
      case "radio":
        return  <RadioFormStep key={`step_${index}`} chapter={chapter} subchapter={subchapter} substep={substep} stepindex={index}  selectionChanged={stepDoneChanged}   />
      case "range":
        return  <RangeStep key={`step_${index}`} chapter={chapter} subchapter={subchapter} substep={substep} stepindex={index}   rangeChanged={stepDoneChanged} />
        case "result":
          return  <ResultStep key={`step_${index}`} chapter={chapter} subchapter={subchapter} substep={substep} stepindex={index} />
     
        default:
        return <Text key={`step_${index}`}>no data</Text>
        break;
    }
  };
  return (
    <View style={styles.container}>
      <View style={styles.topHeading}>
        <LinearGradient
          // Background Linear Gradient
          colors={[
            "rgba(60,60,60,0.65)",
            "rgba(60,60,60,0.2)",
            "rgba(60,60,60,0.01)",
          ]}
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            right: 0,
            height: headerHeight,
          }}
        />
        <View
          style={styles.innerTopHeading}
          onLayout={(event) => {
            find_dimesions(event.nativeEvent.layout, setHeaderHeight);
          }}
        >
          <TouchableOpacity style={styles.minimize} onPress={gotoPrevious}>
            <Icon
              color={Colors.white}
              name="keyboard-arrow-left"
              size={24}
              type="material"
            />
          </TouchableOpacity>
          <Text style={styles.topHeadingText}>
            {/* {i18n.t("educationstep.heading")} */}
            {state.selectedSubstep && state.selectedSubstep.chapter}
          </Text>
          <TouchableOpacity style={styles.minimize} onPress={close}>
            <Icon
              color={Colors.white}
              name="keyboard-arrow-down"
              size={24}
              type="material"
            />
          </TouchableOpacity>
        </View>
      </View>
      <Swiper style={{flex: 1,
      flexDirection: "column",
      justifyContent: "space-between",
      position: "relative",
      alignItems: "stretch",}} ref={swiperRef} onIndexChanged={updateByIndex} >
        {state.substeps && state.substeps.map((step,index) => {

          // return chapter.subchapters.map((subchapter) => {
          //   return subchapter.substeps.map((substep) => {
              return SelectStepType(step.chapter, step.subchapter, step.substep,index);
          //   });
          // });
        })}
      </Swiper>
      {/* {SelectStepType()} */}
      <BottomChapterNav isDone={stepDone} gotoNext={gotoNext} />
    </View>
  );
};
const styles = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "stretch",
    position: "relative",
  },
  topHeading: {
    // flex: 1,
    // flexGrow:0,
    flexDirection: "row",
    justifyContent: "center",
    //  backgroundColor:Colors.beige,
    // alignItems:"center",
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    zIndex: 10,
    // height:50,
    // marginTop: 30,
  },
  innerTopHeading: {
    flex: 1,
    //  position:"relative",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "stretch",
    // backgroundColor:Colors.pink,
    padding: 20,
   // maxWidth: 1400,
  },
  topHeadingText: {
    fontSize: 12,
    marginTop: 5,
    fontWeight: "600",
    //...Texts.chapterHeading,
    color: Colors.white,
    flexShrink: 2,
  },
  minimize: {
    //position: "absolute",
    color: Colors.white,
    //  top: -5,
    //right: 0,
    //zIndex: 10,
    //marginTop: 45,
    //   right: 10,
  },
  //  headerGradient:{
  //   position: "absolute",
  //   top: 0,
  //   left: 0,
  //  right: 0,
  //  height:headerHeight
  //  }
});
export default EducationStep;
