import React from "react";
import {StyleSheet, View, Text, Image ,FlatList,TextInput, TouchableOpacity} from 'react-native';
import Header from '../components/Header';
import Ingress from '../components/Ingress';
import ImageComponent from '../components/Image';
import IconIngress from '../components/IconIngress';
import IconRows from '../components/IconRows';
import IconBox from '../components/IconBox';
import UnderlineIngress from '../components/UnderlineIngress';
import CenteredParagraph from '../components/CenteredParagraph';
import Paragraph from '../components/Paragraph';
import HTML from "react-native-render-html";
const KeysToComponentMap = {
  text:{component:Text,type:"text"},
  title:{component:Header,type:"text"},
  header:{component:Header,type:"text"},
  chapter:{component:Text,type:"text"},
  ingress:{component:Ingress,type:"text"},
  image:{component:ImageComponent,type:"image"},
  icon_ingress:{component:IconIngress,type:"icon_ingress"},
  icon_rows:{component:IconRows,type:"icon_rows"},
  icon_box:{component:IconBox,type:"icon_box"},
  underline_ingress:{component:UnderlineIngress,type:"underline_ingress"},
  paragraph:{component:Paragraph,type:"paragraph"},
  centered_paragraph:{component:CenteredParagraph,type:"centered_paragraph"},
  list:{component:Text,type:"list"},
  json:{component:View,type:"json"},
  wysiwyg:{component:HTML,type:"html"}
};

const renderUnordered = ({ item, index, separators }) =>  (
      <Text>{`\u2022 ${item}`}</Text>
    );
    const renderOrdered= ({ item, index, separators }) =>  (
        <Text>{`${index+1}. ${item}`}</Text>
      );
const renderjson = (config,index) => {
    const componentName = config["type"];

  if (typeof KeysToComponentMap[componentName] !== "undefined") {
    const content = config.data;
    if(KeysToComponentMap[componentName].type=="text"){
        return React.createElement(
            KeysToComponentMap[componentName].component,
            {
             // src: config.src,
              key:"view_"+index
            },
            content.text
          );
    }
    if(KeysToComponentMap[componentName].type=="html"){
        return React.createElement(
            KeysToComponentMap[componentName].component,
            {
             // src: config.src,
              key:"view_"+index,
              source:{html:content.text}
            },
          );
    }
    if(KeysToComponentMap[componentName].type=="list"){
        const isOrdered = content.style=="ordered";
        return React.createElement(
            View,
            {
              key:"view_"+index
            },
            <FlatList
            data={content.items}
            renderItem={isOrdered?renderOrdered:renderUnordered}
            keyExtractor={(x, i) => "view_"+index +"listitem_" + i.toString()}
           // keyExtractor={item => item.id}
            style={{margin: 40}}
          />
          );
    }
  }
}
const renderer = (config,index,style = undefined) => {
    const componentName = config["acf_fc_layout"];

  if (typeof KeysToComponentMap[componentName] !== "undefined") {
    const content = config[componentName];
    if(KeysToComponentMap[componentName].type=="text"){
      const passedStyle = style!==undefined?style:{};
      const theStyle = {...passedStyle, marginTop:20,marginBottom:20};
        return React.createElement(
            KeysToComponentMap[componentName].component,
            {
             // src: config.src,
              key:"view_"+index,
              style:theStyle
            },
            content
          );
    }
    if(KeysToComponentMap[componentName].type=="image"){
      const image = config["image"];
      const position = config["position"];
      return React.createElement(
          KeysToComponentMap[componentName].component,
          {
           // src: config.src,
            key:"view_"+index,
            image:image,
            position:position
          }
        );
  }
    if(KeysToComponentMap[componentName].type=="icon_ingress"){
        const icon = config["icon"];
        const ingress = config["ingress"];
        return React.createElement(
            KeysToComponentMap[componentName].component,
            {
             // src: config.src,
              key:"view_"+index,
              icon:icon,
              ingress:ingress
            }
          );
    }
    if(KeysToComponentMap[componentName].type=="icon_rows"){
     
      return React.createElement(
          KeysToComponentMap[componentName].component,
          {
           // src: config.src,
            key:"view_"+index,
            content:config["icon_rows"]
          }
        );
  }
  if(KeysToComponentMap[componentName].type=="icon_box"){
     
    return React.createElement(
        KeysToComponentMap[componentName].component,
        {
         // src: config.src,
          key:"view_"+index,
          content:config
        }
      );
}
    if(KeysToComponentMap[componentName].type=="underline_ingress"){
      const underlined = config["underlined"];
      const ingress = config["ingress"];
      return React.createElement(
          KeysToComponentMap[componentName].component,
          {
           // src: config.src,
            key:"view_"+index,
            underlined:underlined,
            ingress:ingress,
            style:style
          }
        );
  }
  if(KeysToComponentMap[componentName].type=="paragraph"){
    return React.createElement(
        KeysToComponentMap[componentName].component,
        {
         // src: config.src,
          key:"view_"+index,
          text:config["paragraph"],
          //maxWidth:config["centered_paragraph"],
          //unit:config["type"],
          style:style
        }
      );
}
  if(KeysToComponentMap[componentName].type=="centered_paragraph"){
    return React.createElement(
        KeysToComponentMap[componentName].component,
        {
         // src: config.src,
          key:"view_"+index,
          text:config["centered_paragraph"],
          maxWidth:config["centered_paragraph"],
          unit:config["type"],
          style:style
        }
      );
}
    if(KeysToComponentMap[componentName].type=="html"){
        return React.createElement(
            KeysToComponentMap[componentName].component,
            {
             // src: config.src,
              key:"view_"+index,
              source:{html:content}
            },
          );
    }
    if(KeysToComponentMap[componentName].type=="json"){
      const json = JSON.parse(content);
      if(json && json.blocks && json.blocks.length>0){
        return React.createElement(
            View,
            {
              key:"view_"+index
            },
            json.blocks.map((block,blockindex) => renderjson(block,blockindex))
          //   config.children &&
          //     (typeof config.children === "string"
          //       ? config.children
          //       : config.children.map(c => renderer(c)))
          );
      }
 
    }
  }
}

export default renderer;