import React from 'react';
import { Colors, Buttons, Texts } from "../styles";
import { StyleSheet, Text, View, Dimensions } from 'react-native';
import {
    Icon,
  } from "react-native-elements";
{/* <Icon
                    color={Colors.white}
                    name={props.icon.replace("fas ","").replace("fa-","")}
                    size={60}
                    type="font-awesome"
                /> */}
  //debugger;
function IconBox (props) {
        
  //  const RenderIconRow = (row,index)=>{
        const iconstring = props.content?.icon;
        let icon = "box";
        let theType = "feather";
        if(iconstring && iconstring.length>0){
            const parts = iconstring.split(":");
            if(parts.length===2){
                icon = parts[1];
                theType = parts[0];
            }
        }
            const text = props.content?.text;
            const color = props.content?.color??"salmon";
            const border_color = props.content?.border_color??"transparent";
            const defColor = color!=="transparent"?Colors.white:Colors[border_color];
            const boxColor = color!=="transparent"?Colors[color]:Colors.offWhite;

         // return  (
         
         //   )
     //   };

        return (    
            <View  style={{
                //fontWeight: "bold",
               // margin:20,
             //  marginTop:5,
              // marginBottom:5,
              backgroundColor:boxColor,
              borderColor:Colors[border_color],
              borderWidth:2,
              borderRadius:3,
              width:100,
              height:100,
                flex:-1,
                flexDirection:"row",
                justifyContent:"center",
                alignItems:"center"
            }}>
                <View style={{       
            flex:1,
            flexDirection:"column",
            justifyContent:"flex-start",
            alignItems:"center"
        }}><Icon
                    color={defColor}
                    name={icon}
                    size={26}
                    type={theType}
                    
                />
                 <Text style={{fontSize:11,marginTop:10,textTransform:"uppercase",color:defColor
        }}>{text}</Text></View>
               
            </View>
        )
}



export default IconBox;