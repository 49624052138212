import React, { useState, useRef, useContext, useEffect } from "react";
import {
  View,
  Text,
  TextInput,
  FlatList,
  TouchableOpacity,
  Platform,
ScrollView,
  Dimensions,
  StyleSheet,
  TouchableWithoutFeedback,
  ActivityIndicator
} from "react-native";
import { useGlobalState } from "../services/store";
import ChapterNavigation from "../services/chapternavigation.service";
import { Colors, Buttons, Texts,Inputs } from "../styles";
import i18n from "../services/translations";
import BottomChapterNav from '../components/BottomChapterNav';
//import { Slider } from 'react-native-elements';
import Slider from '@react-native-community/slider';
import {
  Icon,
} from "react-native-elements";
import Renderer from '../helpers/renderer';
const RangeStep = (props) => {

    const NAV_HIDE = 4000;
    const {
    //   chaptersAvailable,
    //   outline,
    //   selectedChapter, 
    //   mainChapter,
      nextChapter,
      previousChapter,
      closeModal,
    } = ChapterNavigation();
    const { state, dispatch } = useGlobalState();
    const hideChapter = () => {
      closeModal();
    };
    const [chapterIcon, setChapterIcon] = useState(undefined);
    const [rangeValue, setRangeValue] = useState(50);
    // const find_dimesions = (layout, setState) => {
    //   const { x, y, width, height } = layout;
    //   setState(height + 20);
    // };
    // const [scrollViewHeight,setScrollViewHeight] = useState(0);
    // const [scrollViewWidth,setScrollViewWidth] = useState(0);
    useEffect(() => {
      //  debugger;
    //   setTimeout(() => {
    //       setNavigationOpen(false);
    //   }, NAV_HIDE);
    }, [props.substep]);
    useEffect(() => {
      if(props.subchapter.chapter_icon){
        const iconparts = props.subchapter.chapter_icon.split(":");
        if(iconparts && iconparts.length===2){
          setChapterIcon({type:iconparts[0],name:iconparts[1]});
        }
       }
      
    }, []);
    // const handleLayout = e =>{
    //     setScrollViewWidth(e.nativeEvent.layout.width);
    //     setScrollViewHeight(e.nativeEvent.layout.height);
    // };
    // const selectEmotion = (emotion)=>{
    //     dispatch({type: 'SET_FORMDATA', payload: {emotion:emotion,propertyname:"emotion"} });
    //     nextChapter();
    // }
    // const deselectBaseEmotion = ()=>{
    //     dispatch({type: 'SET_FORMDATA', payload: {baseemotion:null,propertyname:"baseemotion"} });
    //     previousChapter();
    // }
  const [isLoggedIn, setIsLoggedIn] = useState(false);
//   const windowWidth = Dimensions.get("window").width + "px";
//   const windowHeight = Dimensions.get("window").height + "px";
  const [isLoading, setIsLoading] = useState(true);
  const [helpOpen, setHelpOpen] = useState(false);
  const setRangeChanged = (value,field) => {
      const payload = {propertyname:field};
      payload[field] = value;
    dispatch({type: 'SET_FORMDATA', payload: payload });
    setRangeValue(value);
      if(props.rangeChanged){
        props.rangeChanged(true);
      }

  
  };
  const toggleHelp = () => {
    setHelpOpen(!helpOpen);
  };
  return (

    <View style={styles.container}>
        <View style={{flex:1}}  > 
        <ScrollView  contentContainerStyle={styles.content} >
         
            {/* <View style={{flex:1}}> */}
        <View style={styles.innerContent} >
        <View style={styles.header}>
        {chapterIcon !== undefined && (
                        <View
                          style={{
                            height: 51,
                            width: 51,
                            marginRight:20,
                            flexDirection: "column",
                            justifyContent: "center",
                            alignItems: "center",
                            backgroundColor:
                              Colors[props.subchapter.color],
                          }}
                        >
                          <Icon
                            color={Colors.white}
                            name={chapterIcon.name}
                            size={24}
                            type={chapterIcon.type}
                          />
                        </View>
        )}
        <Text style={styles.headerText}>{props.substep?.title}</Text>
        </View>

         {props.substep && props.substep.view_content && props.substep.view_content.map((component, index) => Renderer(component,index,{color:Colors.white}))} 
         
         {props.substep.help_content && (
                <View style={styles.accordion}>
                  <TouchableOpacity onPress={toggleHelp} style={styles.accordionHeader}>
                    <Icon
                      color={Colors.white}
                      name={
                        helpOpen
                          ? "keyboard-arrow-down"
                          : "keyboard-arrow-right"
                      }
                      size={24}
                      type="material"
                    />
                    <Text style={styles.accordionHeaderText}>{i18n.t("buttons.showmore")}</Text>
                  </TouchableOpacity>
                  {helpOpen &&
                    props.substep.help_content.map((component, index) =>
                      Renderer(component, index, { color: Colors.white })
                    )}
                </View>
              )}
         {/* {props.substep && props.substep.radiochoises && props.substep.radiochoises.map((radiochoise, index)=>( */}

         <View style={{marginTop:30,marginBottom:10,flex:1,flexDirection:"row",justifyContent:"space-between",alignItems:"center"}}>
          {props.substep && props.substep.radiochoises && props.substep.radiochoises.map((radiochoise, index)=>(
          
          //   <RadioButton
           
          //   color={Colors.white}
          //   value="second"
          //   status={ state.formdata[props.substep.radio_data_field]?.data_field === radiochoise.data_field ? 'checked' : 'unchecked' }
          //   onPress={() => setChecked(radiochoise,props.substep.radio_data_field)}
          // />
          <Text  key={`radio_${index}`} style={{fontSize:15, color:Colors.white}}>{radiochoise.choise}</Text>
         
              ))} 
         </View>
            <Slider
         //  key={`range_${index}`}
         allowTouchTrack={true}
         minimumValue={0}
         maximumValue={100}
         onSlidingComplete={(value) => setRangeChanged(value,props.substep?.radio_data_field)}
            value={50}
            minimumTrackTintColor={Colors.white }
            maximumTrackTintColor={Colors.white }
            trackStyle={{ height: 5, backgroundColor: Colors.white }}
            thumbStyle={{ height: 30, width: 30, borderRadius:30, borderColor:Colors.white,borderWidth:2, backgroundColor: Colors[props.subchapter.color] }}
           // status={ state.formdata[props.substep?.radio_data_field]?.data_field === radiochoise.data_field ? 'checked' : 'unchecked' }
            //onValueChange={(value) => setRangeChanged(value,props.substep?.radio_data_field)}
          />
              {/* ))}  */}

         {/* {props.substep && props.substep.textplaceholders && props.substep.textplaceholders.map((textplaceholder, index)=>(
              <TextInput 
              key={`textinput_${index}`}
            //  inputContainerStyle={}
            //  secureTextEntry={true} 
              placeholder={textplaceholder.placeholder}
              multiline={true}
             // numberOfLines={3}
              style={{...Inputs.inputText,height:100, textAlignVertical: 'top'}}
            //   label={props.isRegisterPage?i18n.t('labels.registerpassword'):i18n.t('labels.password') } 
              onChangeText={(e)=>{onChangeText(e,textplaceholder.data_field); }}
              labelStyle={Inputs.labelStyle}/>))}  */}


        </View>
        {/* </View> */}
        </ScrollView>
         </View> 
   

<BottomChapterNav  />     
        
  </View>
        
  );
};
var styles = StyleSheet.create({
      container: {
        flex: 1,
        //height: Platform.OS === 'web' ? '100vh' : '100%',
        backgroundColor: Colors.darkerBlue,
         flexDirection: "column",
         justifyContent: "space-between",
         alignItems: "stretch",
         padding:20
        // position: "relative",
      },
      content: {
      // flex: 1,
        flexGrow: 1,
        flexDirection: "row",
        justifyContent: "center",
        alignItems:"flex-start",
        marginTop:60,
        paddingTop:30,
        paddingBottom:30,
       // alignItems: "flex-start",
      //  backgroundColor: Colors.green,
      },
      header:{
        flex: 1,
        flexDirection: "row",
        justifyContent: "flex-start",
        alignItems:"center",
      },
      headerText:{
        fontSize:21,
        lineHeight:25,
        color:Colors.white
      }, 
      innerContent: {
        flex: 1,
        flexDirection: "column",
        justifyContent: "flex-start",
        alignItems:"stretch",
       // backgroundColor:Colors.pink,
       // flexWrap:"wrap",
       // alignItems: "flex-start",
       paddingBottom:150,
      //  maxWidth: 900,
      },
      accordion: {
        flex: 1,
  
      //flexGrow:0,
       flexDirection: "column",
       justifyContent: "flex-start",
       alignItems:"stretch",
     // marginTop:20
    //  backgroundColor: Colors.green,
    },
    accordionHeader:{
      flexDirection: "row",
      justifyContent: "flex-start",
      alignItems:"flex-start",
     // backgroundColor:Colors.green
    },
    accordionHeaderText:{
      color:Colors.white,
      lineHeight:24
    },
      // topHeading: {
      //  // flex: 1,
      //  // flexGrow:0,
      //   flexDirection: "row",
      //   justifyContent: "center",
      //  // backgroundColor:Colors.beige,
      //   alignItems:"stretch",
      //   position: "relative",
      // //  top: 0,
      //  // left: 0,
      //  // right: 0,
      //  // zIndex: 10,
      // // height:50,
      //   marginTop: 30,
      // },
      emotions:{
        flex: 1,
        flexGrow:0,
        flexDirection: "row",
        flexWrap:"wrap",
        alignItems:"center",
        justifyContent: "center",
      },
      // topHeadingText: {
      //   ...Texts.chapterHeading,
      //   color: Colors.white,
      // },
      emotion:{
          minWidth:120,
          height:120,
          padding:20,
          margin:20,
       // backgroundColor:Colors.salmon

      },
      emotionicon:{
        // minWidth:120,
        // height:120,
        // padding:20,
        // margin:20,
      backgroundColor:Colors.salmon

    },
      emotiontext: {
        ...Texts.bright,
        textAlign:"center"
      },
      // minimize: {
      //  position: "absolute",
      //   color: Colors.white,
      //   top: -5,
      //   //right: 0,
      //   //zIndex: 10,
      //   //marginTop: 45,
      //   right: 10,
      // },
   
      horizontalSpace: {
        flex: 1,
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
      },
  });
export default RangeStep;