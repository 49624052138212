import React, {useState,useEffect,useRef} from 'react';
import {StyleSheet, Text, View,TouchableOpacity,Image,Animated } from 'react-native';
import { Texts, Colors } from '../styles';
import ImageComponent from '../components/Image';
import {arraysEqual} from '../helpers/functions';
//import * as Animatable from 'react-native-animatable';

const  SubChapter =  ({chapter,subchapter,setSelected,index,selectedChapter}) => {
        const resolveAssetSource = Image.resolveAssetSource;
       // const viewAnimation = useRef(null);
    //   const anim = useRef(new Animated.Value(0));
        const handleSelect = () =>{
            setSelected(subchapter.id);
           
           // setIsSelected(true);
        };
     //   const [viewWidth,setViewWidth] = useState(0);
        const [isSelected,setIsSelected] = useState(false);
    //    const [showView, setShowView] = useState(isSelected);
    //    const [imageHeight,setImageHeight] = useState(0);
        // const  find_dimesions = (layout)=>{
        //     const {x, y, width, height} = layout;
        //     const wantedWidth = width-50;
        //     if(subchapter.image && wantedWidth>0 && wantedWidth!==viewWidth){
        //         setViewWidth(wantedWidth);
        //     }
        //   };
        //   useEffect(() => {
        //       if(subchapter.image){
        //         Image.getSize(
        //             subchapter.image,
        //             (width, height) => {
        //                 const ratio = viewWidth/width;
        //                 setImageHeight(ratio*height);
        //             },
        //             (err) =>{console.log(err);}
        //         );
        //       }
        // }, [viewWidth]);
        useEffect(() => {
            setIsSelected(selectedChapter===subchapter.id);
        }, [selectedChapter]);
        useEffect(() => {

            // if(isSelected){
            //     debugger;
            // }
           
            // const Animation = async () => {
    
            //     if (isSelected) {
            //         setShowView(true);
            //         if (viewAnimation.current)
            //             await viewAnimation.current.bounceInDown(1500);
            //     } else {
            //         if (viewAnimation.current)
            //             await viewAnimation.current.bounceOutUp(1500);
            //         setShowView(false)
            //     }
            // }
    
            // Animation();
          //  if(anim.current){
            // if (isSelected) {
            //     Animated.timing(
            //         anim.current,
            //         {
            //           toValue: 300,
            //           duration: 800,
            //           useNativeDriver: false,
            //         },
                
            //       ).start();
            // } else {
            //     Animated.timing(
            //         anim.current,
            //         {
            //           toValue: 0,
            //           duration: 800,
            //           useNativeDriver: false,
            //         }
            //       ).start();
              
            // }
      //  }
         
        }, [isSelected]);
          
        //onLayout={(event) => { find_dimesions(event.nativeEvent.layout) }}
        //onLayout={(event) => { find_dimesions(event.nativeEvent.layout) }}
        const badgeStyle = {
            borderRadius:25,
            backgroundColor: isSelected?Colors.darkGrey:Colors.lightGrey,
         //  position:"absolute",
          //  zIndex:20,
           // left:13,
           // top:13,
            width:12,
            height:12,
            marginTop:4,
            marginBottom:4,
            marginRight:20,
            flex:-1
          //  borderWidth:1,
          //  borderStyle:"solid",
           // borderColor: Colors.darkGrey,
           // marginRight:10
        };
        return ( 
           <View style={styles.outercontainer} >
            
 
        <TouchableOpacity style={styles.container} onPress={handleSelect}> 
        <View style={badgeStyle}></View>
        {/* <View style={isSelected?styles.selectedInnerBadge:styles.innerBadge}></View> */}
        <Text style={styles.heading}>{subchapter.title}</Text>
        </TouchableOpacity >
        {/* <Animated.View style={{ maxHeight: anim.current, overflow:"hidden" }}> */}
        { isSelected && subchapter.screenshot && (
            <ImageComponent image={subchapter.screenshot} position="center" limitHeight={true} />
        // <View style={{marginLeft:50}}>
        //     <Image style={{width:viewWidth,height:imageHeight}} resizeMode="contain" source={{ uri: subchapter.image }} />
        //     </View>
            
            )}
        {/* </Animated.View> */}
        </View>
               
        );
}
const styles = StyleSheet.create({
    outercontainer:{
       // display:"flex",
     //   zIndex:20,
     marginTop:10,
     marginBottom:10,
        flex:1,
       // flexGrow:2,
        flexDirection:"column",
        justifyContent:"flex-start",
        alignItems:"stretch",
       // alignContent:"flex-start",
       // backgroundColor:Colors.green
    },
    container:{
        //display:"flex",
    //    zIndex:20,
        flex:1,
        flexDirection:"row",
        justifyContent:"flex-start",
      //  alignItems:"center",
      //  alignItems:"center",
      //  alignContent:"flex-start",
       // marginVertical:10,
    },
    // badge:{
    //     borderRadius:25,
    //     backgroundColor:Colors.white,
    //    position:"absolute",
    //     zIndex:20,
    //     width:40,
    //     height:40,
    //     borderWidth:1,
    //     borderStyle:"solid",
    //     borderColor: Colors.darkGrey,
    //     marginRight:10
    // },
    // selectedBadge:{
    //     borderRadius:25,
    //     backgroundColor:Colors.darkBlue,
    //    position:"absolute",
    //     zIndex:20,
    //     width:40,
    //     height:40,
    //     borderWidth:1,
    //   borderStyle:"solid",
    //   borderColor: Colors.darkGrey,
    //     marginRight:10
    // },
 
    // selectedBadge:{
    //     borderRadius:25,
    //     backgroundColor:Colors.white,
    //    position:"absolute",
    //    zIndex:20,
    //     left:13,
    //     top:13,
    //     width:14,
    //     height:14,
    //     borderWidth:1,
    //     borderStyle:"solid",
    //     borderColor: Colors.darkGrey,
    //     marginRight:10
       
       
    // },
    heading:{
        flex:-1,
        ...Texts.subchapterHeading,
      // lineHeight:40,
     //  height:40,
      // paddingLeft:50
    }
});
export default SubChapter;