import React, {useState, useEffect} from 'react';

import { Modal, Portal, Appbar,Menu } from 'react-native-paper';
import { StyleSheet, Platform} from 'react-native';
import {useGlobalState} from '../services/store';
import AsyncStorage from '@react-native-async-storage/async-storage';
import AuthService from "../services/auth.service";
import {useNavigationState} from '@react-navigation/native';
//import strings from '../services/translations';
import i18n from '../services/translations';
import Login from './Login';
// const LoggedinMenu = (props) => {
//     return(
//         <Menu.Item onPress={() => {console.log('Option 1 was pressed')}} title="Option 1" />
//           <Menu.Item onPress={() => {console.log('Option 2 was pressed')}} title="Option 2" />
//           <Menu.Item onPress={() => {console.log('Option 3 was pressed')}} title="Option 3" disabled />
//     );
// }
// const LoggedoutMenu = (props) => {

// }

const  CustomHeader = ({ navigation, previous }) => {
    const [modalVisible, setModalVisible] = useState(false);
    const showModal = () => setModalVisible(true);
    const hideModal = () => setModalVisible(false);
    const containerStyle = {backgroundColor: 'white', padding: 20,minHeight:400};
    //const route = useRoute();
    const {state, dispatch} = useGlobalState();
    const [isLoggedIn,setIsLoggedIn] = useState(false);
    const [visible, setVisible] = useState(false);
    const [locale, setLocale] = useState(i18n.locale);
    const openMenu = () => setVisible(true);
    const closeMenu = () => setVisible(false);
 
    const logout = () => {
        AuthService.logout().then(
            () => {
                 dispatch({type: 'SET_USER', payload: null});
              //props.history.push("/profile");
            //  window.location.reload();
            },
            (error) => {
                dispatch({type: 'SET_USER', payload: null});
            }
          );
    };
    const changeLanguage = (locale,persist = true)=>{
       // strings.setLanguage(locale);
        i18n.locale = locale;
        setLocale(locale);
        dispatch({type: 'SET_LOCALE', payload: locale});
        if(persist){
            AsyncStorage.setItem('lang', locale);
        }
    };
    useEffect(() => {
        AsyncStorage.getItem('lang').then((lang)=>{
            if(lang!=null){
                changeLanguage(lang,false);    
            }
           
           });
           //debugger;
    }, []);
    useEffect(() => {
        setIsLoggedIn(state.user && state.user.token);
    }, [state.user/*,locale*/]);
    // useEffect(() => {
      
    // }, [route]);
  //  const routename = 'navigation.'+route.name;
  const screenName = 'navigation.'+ useNavigationState((state) => state.routes[state.index].name)
    const languageMenu = locale == 'sv' ? <Menu.Item onPress={() => {changeLanguage('en')}} title="Engelska" />
:<Menu.Item onPress={() => {changeLanguage('sv')}} title="Swedish" />;
    
  return (
      <>
    { true && <Appbar.Header style={{backgroundColor:"transparent",width:"100%", borderBottomWidth: 0, shadowColor: 'transparent',elevation: 0,position:"absolute"}} >
         {previous ? <Appbar.BackAction style={{display:"none"}} onPress={navigation.goBack} /> : null}
          <Appbar.Content style={{opacity:0}} title={i18n.t('appTitle', { appName: i18n.t('appName'),view:i18n.t(screenName)})}  />
      <Appbar.Action icon="help-circle-outline"  />
      {/* <Appbar.Action icon="account-outline" onPress={()=>{navigation.navigate('Profile');}}  /> */}   
       { isLoggedIn ?  <Menu
          visible={visible}
          onDismiss={closeMenu}
          anchor={
            <Appbar.Action icon='account'  onPress={openMenu} />
          }>  
       <Menu.Item onPress={() => {navigation.navigate('Profile')}} title={i18n.t('profile')} />
       <Menu.Item onPress={logout} title={i18n.t('buttons.logout')}  />
        {languageMenu}
       
       </Menu>
          :  <Menu
          visible={visible}
          onDismiss={closeMenu}
          anchor={
            <Appbar.Action icon='account-outline' onPress={openMenu} />
          }><Menu.Item onPress={showModal} title={i18n.t('buttons.login')}  />
            {languageMenu}
          </Menu>
        }
           <Portal>
        <Modal visible={modalVisible} onDismiss={hideModal} contentContainerStyle={containerStyle}>
          <Login />
        </Modal>
      </Portal>
    </Appbar.Header>
}
    </>
  );
}

export default CustomHeader;