import React, { createContext, useReducer, useState, useContext } from "react";
//import Reducer from './reducer'

const Reducer = (state, action) => {
  switch (action.type) {
    case "SET_ERROR":
      return {
        ...state,
        error: action.payload,
      };
    case "SET_USER":
      return {
        ...state,
        user: action.payload,
      };
    case "SET_PUBLIC_CONTENT":
      return {
        ...state,
        publiccontent: action.payload,
      };
    case "SET_PROTECTED_CONTENT":
      return {
        ...state,
        protectedcontent: action.payload,
      };
    case "SET_OUTLINE":
      return {
        ...state,
        outline: action.payload,
      };
    case "SET_SUBSTEPS":
      return {
        ...state,
        substeps: action.payload,
      };
    case "SET_EMOTIONS":
      return {
        ...state,
        baseemotions: action.payload,
      };
    case "SET_ISINEDUCATION":
      return {
        ...state,
        isInEducation: action.payload,
      };
    case "SET_SHOW_HEADER":
      return {
        ...state,
        showHeader: action.payload,
      };
    case "SET_LOCALE":
      return {
        ...state,
        locale: action.payload,
      };
    case "SET_SELECTED_INDEX":
      //  debugger;
      return {
        ...state,
        selectedIndex: action.payload,
      };
    case "SET_SELECTED_CHAPTER":
      //  debugger;
      return {
        ...state,
        selectedChapter: action.payload.chapter,
        selectedSubchapter: action.payload.subchapter,
        selectedSubstep: action.payload.substep,
      };
    case "SET_FORMDATA":
      //debugger;
      const prop = action.payload.propertyname;
      state.formdata[prop] = action.payload[prop];
      // return {
      //     ...state,
      //     formdata["baseemotion"]: action.payload
      // };
      return Object.assign({}, state);
    //    case 'SET_EMOTION':
    //     state.formdata.baseemotion  = action.payload;
    //     // return {
    //     //     ...state,
    //     //     formdata["baseemotion"]: action.payload
    //     // };
    //    return Object.assign({}, state);
    // case 'SET_MODAL_VISIBLE':
    //     return {
    //         ...state,
    //         modalVisible: action.payload
    //     };
    // case 'SET_MODAL_CONTENT':
    //     return {
    //         ...state,
    //         modalContent: action.payload
    //     };
    default:
      return state;
  }
};

//const Context = createContext();
const initialState = {
  // educationsteps: [],
  protectedcontent: [],
  outline: [],
  substeps: [],
  baseemotions: [],
  publiccontent: [],
  error: null,
  user: null,
  showHeader: false,
  locale: "sv",
  selectedChapter: null,
  selectedSubchapter: null,
  selectedSubstep: null,
  selectedIndex: null,
  formdata: {},
  isInEducation: false,
  // modalVisible:false,
  // modalContent:null
};
const StateContext = createContext(initialState);
function useGlobalState() {
  const context = useContext(StateContext);
  if (!context) {
    throw new Error(`useGlobalState must be used within a Store`);
  }
  const [state, dispatch] = context;
  return {
    state,
    dispatch,
  };
  // return context
}
export { useGlobalState };
//const [state, dispatch] = useReducer(Reducer, initialState);
// const Store = ({children}) => {

//     return (
//         <Context.Provider value={[state, dispatch]}>
//             {children}
//         </Context.Provider>
//     )
// };
const Store = (props) => {
  const [state, dispatch] = useReducer(Reducer, initialState);
  //  const [stateValue, setStateValue] = React.useState(initialState);
  const value = React.useMemo(() => [state, dispatch], [state]);
  return <StateContext.Provider value={value} {...props} />;
};
//export const Context = createContext(state, dispatch);
export default Store;
