import React, { useState, useRef, useContext, useEffect } from "react";
import {
  View,
  Text,
  FlatList,
  TouchableOpacity,
  Platform,
ScrollView,
  Dimensions,
  StyleSheet,
  TouchableWithoutFeedback,
  ActivityIndicator
} from "react-native";
import { useGlobalState } from "../services/store";
import ChapterNavigation from "../services/chapternavigation.service";
import { Colors, Buttons, Texts,Inputs } from "../styles";
import i18n from "../services/translations";
import BottomChapterNav from '../components/BottomChapterNav';
import { Input } from 'react-native-elements';
import {
  Icon,
} from "react-native-elements";
import {
  RadioButton
} from "react-native-paper";
import Renderer from '../helpers/renderer';
const RadioFormStep = (props) => {

   // const NAV_HIDE = 4000;
    // const {
    // //   chaptersAvailable,
    // //   outline,
    // //   selectedChapter,
    // //   mainChapter,
    //   nextChapter,
    //   previousChapter,
    //   closeModal,
    // } = ChapterNavigation();
    const { state, dispatch } = useGlobalState();
    // const hideChapter = () => {
    //   closeModal();
    // };
    // useEffect(() => {
    // }, [props.substep]);

    useEffect(() => {
      if(props.subchapter.chapter_icon){
        const iconparts = props.subchapter.chapter_icon.split(":");
        if(iconparts && iconparts.length===2){
          setChapterIcon({type:iconparts[0],name:iconparts[1]});
        }
       }
      
    }, []);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [chapterIcon, setChapterIcon] = useState(undefined);
  //const [checked, setChecked] = useState("");
  const setChecked = (value,field) => {
      const payload = {propertyname:field};
      payload[field] = value;
      if(props.selectionChanged){
        props.selectionChanged(true);
      }
    dispatch({type: 'SET_FORMDATA', payload: payload });
  };
 
  return (
    <View style={styles.container}>
        <View style={{flex:1}}  > 
        <ScrollView  contentContainerStyle={styles.content} >
        <View style={styles.innerContent} >
        <View style={styles.header}>
        {chapterIcon !== undefined && (
                    
                        <View
                          style={{
                            height: 51,
                            width: 51,
                            marginRight:20,
                            flexDirection: "column",
                            justifyContent: "center",
                            alignItems: "center",
                            backgroundColor:
                              Colors[props.subchapter.color],
                          }}
                        >
                          <Icon
                            color={Colors.white}
                            name={chapterIcon.name}
                            size={24}
                            type={chapterIcon.type}
                          />
                        </View>
        )}
        <Text style={styles.headerText}>{props.substep.title}</Text>
        </View>
        {props.substep.emotion_select_text && <Text style={styles.emotionselecttext}>{props.substep.emotion_select_text}</Text>}

         {props.substep && props.substep.view_content && props.substep.view_content.map((component, index) => Renderer(component,index))} 

         {props.substep && props.substep.radiochoises && props.substep.radiochoises.map((radiochoise, index)=>(
           <View style={{flex:1,flexDirection:"row",justifyContent:"flex-start",alignItems:"center",marginLeft:20}}>
            <RadioButton
            key={`radio_${index}`}
            color={Colors.white}
            value="second"
            status={ state.formdata[props.substep.radio_data_field]?.data_field === radiochoise.data_field ? 'checked' : 'unchecked' }
            onPress={() => setChecked(radiochoise,props.substep.radio_data_field)}
          />
          <Text style={{fontSize:15, color:Colors.white}}>{radiochoise.choise}</Text>
          </View>
              ))} 
        {/* <TouchableOpacity style={{width:'100%'}} onPress={nextChapter}> 
            <Text style={Buttons.smallRounded} >
                {i18n.t('buttons.continue') } 
            </Text>
            </TouchableOpacity > */}
        </View>
        </ScrollView>
         </View> 
{/* <BottomChapterNav  />          */}
  </View>);
};
var styles = StyleSheet.create({
  container: {
    flex: 1,
  
    backgroundColor: Colors.darkerBlue,
     flexDirection: "column",
     justifyContent: "space-between",
     alignItems: "stretch",
     padding:20,
    
    // position: "relative",
  },
  content: {
  // flex: 1,
    flexGrow: 1,
    flexDirection: "row",
    justifyContent: "center",
    alignItems:"flex-start",
    marginTop:60,
    paddingTop:30,
    paddingBottom:30,
   // alignItems: "flex-start",
  //  backgroundColor: Colors.green,
  },
      header:{
        flex: 1,
        flexDirection: "row",
        justifyContent: "flex-start",
        alignItems:"center",
      },
      headerText:{
        flex:1,
        fontSize:21,
        lineHeight:25,
        color:Colors.white
      }, 
      innerContent: {
        flex: 1,
        flexDirection: "column",
        justifyContent: "flex-start",
        alignItems:"stretch",
       // backgroundColor:Colors.pink,
       // flexWrap:"wrap",
       // alignItems: "flex-start",
       paddingBottom:150,
      //  maxWidth: 900,
      },
      // topHeading: {
      //  // flex: 1,
      //  // flexGrow:0,
      //   flexDirection: "row",
      //   justifyContent: "center",
      //  // backgroundColor:Colors.beige,
      //   alignItems:"stretch",
      //   position: "relative",
      // //  top: 0,
      //  // left: 0,
      //  // right: 0,
      //  // zIndex: 10,
      // // height:50,
      //   marginTop: 30,
      // },
      emotionselecttext: {
        ...Texts.bright,
        marginTop:40,
        marginBottom:40,
        //textAlign:"center",
      },
      emotions:{
        flex: 1,
        flexGrow:0,
        flexDirection: "row",
        flexWrap:"wrap",
        alignItems:"center",
        justifyContent: "center",
      },
      // topHeadingText: {
      //   ...Texts.chapterHeading,
      //   color: Colors.white,
      // },
      emotion:{
          minWidth:120,
          height:120,
          padding:20,
          margin:20,
       // backgroundColor:Colors.salmon

      },
      emotionicon:{
        // minWidth:120,
        // height:120,
        // padding:20,
        // margin:20,
      backgroundColor:Colors.salmon

    },
      emotiontext: {
        ...Texts.bright,
        textAlign:"center"
      },
      // minimize: {
      //  position: "absolute",
      //   color: Colors.white,
      //   top: -5,
      //   //right: 0,
      //   //zIndex: 10,
      //   //marginTop: 45,
      //   right: 10,
      // },
   
      horizontalSpace: {
        flex: 1,
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
      },
  });
export default RadioFormStep;