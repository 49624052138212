export const Colors = {
    darkBlue:"#2F80ED",
    darkerBlue:"#223B5C",
    blue:"#2A66AE",
    green:"#44944C",
    orange:"#E8A150",
    white:"#FFFFFF",
    offWhite:"#F6F3EE", 
    lightGrey:"#e3e3e3",
    grey:"#848484",//7B8794
    darkGrey:"#323f4b",
    opaqueGrey:"#28446988",
    darkerGrey:"#26303a",
    lightBeige:"#f4f0ec",
    beige:"#d9d5cd",
    salmon:"#E06868",
    red:"#ff0000",
    pink:"#E9A9A0",
    black:"#000000",
    opaqueWhite:"rgba(255, 255, 255, 0.2)",
    transparent:"transparent"
}
