// import { StatusBar } from 'expo-status-bar';
import React from 'react';
import {SafeAreaView,StatusBar } from 'react-native';
import Navigation from './Views/Navigation';
import { Provider as PaperProvider } from 'react-native-paper';
import Store from './services/store';
import Root from './Root';
import {GlobalStyles} from './styles';
//const isWeb = Platform.OS === 'web';
  const App = props =>{
    return (
      <SafeAreaView style={GlobalStyles.droidSafeArea}><Store><PaperProvider><Root><Navigation  /></Root></PaperProvider></Store></SafeAreaView>
    );
}

export default App;
           