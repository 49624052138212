import React from 'react';
import { Colors, Buttons, Texts } from "../styles";
import { StyleSheet, Text, View, Dimensions } from 'react-native';
import {
    Icon,
  } from "react-native-elements";
{/* <Icon
                    color={Colors.white}
                    name={props.icon.replace("fas ","").replace("fa-","")}
                    size={60}
                    type="font-awesome"
                /> */}
  //debugger;
function IconRows (props) {
        
    const RenderIconRow = (row,index)=>{
        const iconstring = row.icon;
        let icon = "box";
        let theType = "feather";
        if(iconstring && iconstring.length>0){
            const parts = iconstring.split(":");
            if(parts.length===2){
                icon = parts[1];
                theType = parts[0];
            }
        }
      
            const text = row.text;
            const color = row.color??"salmon";
            const border_color = row.border_color??"salmon";
            const bold_text = row.bold_text;


          return  (<View key={`icon_row_${index}`} style={{
                //fontWeight: "bold",
               // margin:20,
               marginTop:5,
               marginBottom:5,
                flex:1,
                flexDirection:"row",
                justifyContent:"flex-start",
                alignItems:"center"
            }}>
                <View style={{
            backgroundColor:Colors[color],
            borderColor:Colors[border_color],
            borderWidth:2,
            borderRadius:3,
            width:26,
            height:26,
            marginRight:15,
            flex:-1,
            flexDirection:"row",
            justifyContent:"center",
            alignItems:"center"
        }}><Icon
                    color={color!=="transparent"?Colors.white:Colors[border_color]}
                    name={icon}
                    size={11}
                    type={theType}
                /></View>
                <Text style={{fontSize:15
        }}>
                <Text style={{fontWeight:"bold",
        }}>{bold_text}</Text>
         <Text style={{marginLeft:4}}>{text}</Text>
        </Text>
            </View>)
        };

        return (    
            <View  style={{
                //fontWeight: "bold",
                flex:1,
                flexDirection:"column",
                justifyContent:"flex-start",
                alignItems:"stretch"
            }}>
            {props.content && props.content.length>0 && props.content.map(RenderIconRow)} 

            </View>
        )
}



export default IconRows;