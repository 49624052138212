import React from 'react';
import { StyleSheet, Text, View, Dimensions } from 'react-native';

const {width, height} = Dimensions.get('window');

function Header (props) {
    const propstyles = {};
    if(props.align && props.align=="center"){
        propstyles.textAlign = "center";
    }

        return (
            <View  style={StyleSheet.flatten([styles.header,propstyles])}>
                <Text style={styles.text}>{props.children}</Text>
            </View>)
}
const styles = StyleSheet.create({
        header: {
            flex:1,
            marginTop:20,
            marginBottom:20,
        },
        text:{
            fontWeight:"bold",
            fontSize:21
        },
});



export default Header;