import axios from 'axios';
import authHeader from './auth-header';
import AsyncStorage from '@react-native-async-storage/async-storage';
const API_URL = 'https://appcm.insightgap.com/wp-json/insightgap/v1/';



//class ContentService {
  /*const getPublicContent = () => {
    return new Promise((resolve,reject)=>{
      axios.get(API_URL + 'public').then((data)=>{
        try {
          AsyncStorage.setItem('publiccontent', JSON.stringify(data)).then(()=>{
          
           return data;
          });
         
       } catch (e) {
         return null;
         // saving error
       }
      });
        return null;


    })
 
  }

  const getProtectedContent = () => {
    axios.get(API_URL + 'protected', { headers: authHeader() }).then((data)=>{
      try {
        AsyncStorage.setItem('protectedcontent', JSON.stringify(data)).then(()=>{
      
         return data;
        });
       
     } catch (e) {
       return null;
       // saving error
     }
    });
      return null;
  }
*/
const combine = (data)=>{
  if(data.outline && data.outline.sv && data.outline.en){
    combineLanguage(data,"sv");
    combineLanguage(data,"en");
  }
  //debugger;
  return data;
  
}
const combineLanguage = (data,lang) =>{
   for(let i = 0;i< data.outline[lang].chapters.length;i++){
      const chapter = data.outline[lang].chapters[i];
      for(let j = 0;j< chapter.subchapters.length;j++){
          const subchapter = chapter.subchapters[j];
          if(subchapter.screenid){
            copyViewData(subchapter,data.views,subchapter.screenid,lang);
            for(let k = 0;k< subchapter.substeps.length;k++){
              const substep = subchapter.substeps[k];
              if(substep.screenid){
                copyViewData(substep,data.views,substep.screenid,lang);
              }
            }
          }
      
      }

   }

}
const copyViewData = (obj,views,viewId,lang) =>{
  const currentview = views.find((view)=>{return view.id === viewId});
  if(currentview){
    if(currentview.type){
      obj.type = currentview.type;
      obj.color = currentview.color;
      if(currentview.chapter_icon !==undefined){
        
        obj.chapter_icon = currentview.chapter_icon.replace("fas ","").replace("far ","").replace("fa-","");
      }else{
        obj.chapter_icon = false
      }
     
      // const colonIndex = currentview.type.indexOf(":");
      // if(colonIndex>-1){
      //   obj.type = currentview.type.substr(0,colonIndex);
      // }   
    }
    if(currentview[lang]){
    Object.entries(currentview[lang]).map(prop => {
      obj[prop[0]] = prop[1];//currentview[lang][prop];
    });
  }
  
  }
 // return obj;
}
  const internalRefresh = (name,data) =>{
    return new Promise((resolve,reject)=>{
      try {
        const thedata = data!==undefined && data.data!==undefined?data.data:data;
        AsyncStorage.setItem(name, JSON.stringify(thedata)).then(()=>{
          resolve(thedata);
        });
      
    } catch (e) {
      reject(e);
    }
  });
  }
  const internalGet = (name) =>{
    return new Promise((resolve,reject)=>{
    try {
      AsyncStorage.getItem(name).then((data)=>{
      resolve(data);
      });
    
  } catch (e) {
    reject(e);
  }
});
}
const getPublicContent = () => {
  return new Promise((resolve,reject)=>{
  axios.get(API_URL + 'public').then((data)=>{
    internalRefresh('publiccontent',data).then(
      (storeddata)=>{resolve(storeddata)},
      (error)=>{reject(error)});
  },(error)=>{
    internalGet('publiccontent').then(
      (data)=>{resolve(data)},
      (error)=>{reject(error)});
  });

});
}
const getProtectedContent = () => {
  return new Promise((resolve,reject)=>{
    authHeader().then((auth)=>{
      axios.get(API_URL + 'protected',{ headers: auth }).then((data)=>{

        internalRefresh('protectedcontent',combine(data.data)).then(
          (storeddata)=>{resolve(storeddata)},
          (error)=>{reject(error)});
      },(error)=>{
        internalGet('protectedcontent').then(
          (data)=>{resolve(data)},
          (error)=>{reject(error)});
      });
    
    },(error)=>{
      reject(error);
    })

});
}
  // const getProtectedContent = () => {
  //   axios.get(API_URL + 'educationsteps', { headers: authHeader() }).then((data)=>{
  //     return internalRefresh('protectedcontent','SET_EDUCATION_CONTENT',data);
  //   },(error)=>{
  //     return internalGet('protectedcontent','SET_EDUCATION_CONTENT');
  //   });

    
  // }


//   getModeratorBoard() {
//     return axios.get(API_URL + 'mod', { headers: authHeader() });
//   }

//   getAdminBoard() {
//     return axios.get(API_URL + 'admin', { headers: authHeader() });
//   }
//}

export default {
//  getPublicContent,
//  getProtectedContent,
  getPublicContent,
  getProtectedContent
};