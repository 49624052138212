 //import  { useContext } from "react";
import axios from "axios";
//import {Context} from './/store'
import AsyncStorage from '@react-native-async-storage/async-storage';
import { registerRootComponent } from "expo";
const API_URL = "https://appcm.insightgap.com/wp-json/jwt-auth/v1/";

//class AuthService {

 // const [state, dispatch] = useContext(Context);
  const register = (username, password,custom_auth) =>{
    return new Promise((resolve,reject)=>{ 
    let data;
    let postData = {
      username,
      password,
    };
    if(custom_auth!==undefined){
      postData.custom_auth=  custom_auth;
    }
     axios
      .post(API_URL + "token",postData )
      .then(response => {
        data = response.data;
        if (data.success) {
          try {
              const user = {
                token:data.data.token,
                usr:username,
                pwd:password
              }
               AsyncStorage.setItem('user', JSON.stringify(user)).then(()=>{
                // dispatch({type: 'SET_USER', payload: data});
                resolve(user);
               });
              
            } catch (e) {
              AsyncStorage.removeItem('user', null).then(()=>{
                // dispatch({type: 'SET_USER', payload: null});
                reject(null);
               });
              // saving error
            }
          }
       
    
      },error=>{
        AsyncStorage.removeItem('user', null).then(()=>{
          // dispatch({type: 'SET_USER', payload: null});
          reject(null);
         });
        reject(null);
      });


    });
  }
  const login = (username, password) =>{
    return register(username,password,undefined);
  }
  const logout = () => {
    return new Promise((resolve,reject)=>{ 
    try{
    AsyncStorage.removeItem("user").then(()=>{
     // dispatch({type: 'SET_USER', payload: null});
      resolve(true);
    });
    }
    catch(exception){
      reject("failed to remove user data");
    }
  });
  }

  // const register = (username, password,custom_auth) => {
  //   return axios.post(API_URL + "token", {
  //     username,
  //     password,
  //     custom_auth
  //   });
  // }

  const getCurrentUser=  () =>{
    return new Promise((resolve,reject)=>{ 
    try {
       
        AsyncStorage.getItem('user').then((user)=>{
          if(user !== null) {
            resolve(JSON.parse(user));
        }else{
          resolve(null);
        }
        });
       
      } catch(e) {
        reject(null);
        // error reading value
      }
   

    });

  }
//}

export default {
    register,
    login,
    logout,
    getCurrentUser,
  };