import * as Buttons from './buttons';
import  {Colors} from './colors';
import * as Typography from './typography';
import * as Inputs from './inputs';
import * as Layout from './inputs';
import * as Texts from './texts';
import { StyleSheet, Platform } from 'react-native';
const GlobalStyles = StyleSheet.create({
    droidSafeArea: {
        flex: 1,
      //  backgroundColor: npLBlue,
        paddingTop: Platform.OS === 'android' ? 25 : 0
    },
});

export { Typography, Colors, Buttons,Inputs,Layout,Texts,GlobalStyles }