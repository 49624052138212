import React, { useState, useRef, useContext, useEffect } from "react";
import {
  View,
  Text,
  FlatList,
  TouchableOpacity,
  Platform,
ScrollView,
  Dimensions,
  StyleSheet,
  TouchableWithoutFeedback,
} from "react-native";
import { useGlobalState } from "../services/store";
import ChapterNavigation from "../services/chapternavigation.service";
import { Colors, Buttons, Texts } from "../styles";
import { Joy,Lust,Fear,Anger,Shame,Sadness,Distaste,EMOTIONMAP} from "../helpers/basefeeling-assets";
//import i18n from "../services/translations";
import BottomChapterNav from '../components/BottomChapterNav';
import {getTranslation} from '../helpers/functions';
import {
  Icon,
} from "react-native-elements";
const EmotionStep = (props) => {

    const NAV_HIDE = 4000;
    // const {
    // //   chaptersAvailable,
    // //   outline,
    // //   selectedChapter,
    // //   mainChapter,
    //   nextChapter,
    //   previousChapter,
    //   closeModal,
    // } = ChapterNavigation();
    const { state, dispatch } = useGlobalState();
    // const hideChapter = () => {
    //   closeModal();
    // };
    // const find_dimesions = (layout, setState) => {
    //   const { x, y, width, height } = layout;
    //   setState(height + 20);
    // };
    // const [scrollViewHeight,setScrollViewHeight] = useState(0);
    // const [scrollViewWidth,setScrollViewWidth] = useState(0);
    // useEffect(() => {
    //   //  debugger;
    // //   setTimeout(() => {
    // //       setNavigationOpen(false);
    // //   }, NAV_HIDE);
    //   if(!(state.formdata.baseemotion!==null && state.formdata.baseemotion!==undefined)){
    //     previousChapter();
    //   }

    // }, [state.formdata?.baseemotion]);
    useEffect(() => {
      if(props.emotionSelectedChanged){
        props.emotionSelectedChanged(state.formdata?.emotion!==null &&state.formdata?.emotion!==undefined);
      }
      
      }, [state.formdata?.emotion]);

    const selectEmotion = (emotion)=>{
        dispatch({type: 'SET_FORMDATA', payload: {emotion:emotion,propertyname:"emotion"} });
      //  nextChapter();
    }
    const deselectBaseEmotion = ()=>{
        dispatch({type: 'SET_FORMDATA', payload: {baseemotion:null,propertyname:"baseemotion"} });
        previousChapter();
    }
  const [isLoggedIn, setIsLoggedIn] = useState(false);
//   const windowWidth = Dimensions.get("window").width + "px";
//   const windowHeight = Dimensions.get("window").height + "px";
  const [isLoading, setIsLoading] = useState(true);
  const emotionstyle = {
    borderRadius:70,
    borderColor:Colors.white,
    borderWidth:1,
     paddingTop:10,
     paddingBottom:10,
     paddingLeft:15,
     paddingRight:15,
     margin:10,
  };
  const  emotiontextstyle ={
    ...Texts.bright,
    fontSize:22,
    lineHeight:25,
    textAlign:"center",
  };
  const  baseemotiontext ={
    ...Texts.bright,
    marginTop:10,
    borderColor:Colors.white,
    borderRadius:25,
    padding:10,
    borderWidth:1,
    backgroundColor:Colors.salmon
    //textAlign:"center",
  };
  const [chapterIcon, setChapterIcon] = useState(undefined);
  useEffect(() => {
    if(props.subchapter.chapter_icon){
      const iconparts = props.subchapter.chapter_icon.split(":");
      if(iconparts && iconparts.length===2){
        setChapterIcon({type:iconparts[0],name:iconparts[1]});
      }
     }
    
  }, []);
  const RenderEmotion = (emotion,index) =>{
    let selectedStyleText = {};
    let selectedStyleBorder = {};
  if(emotion.id === state.formdata.emotion?.id){
    selectedStyleText.color = Colors.darkerGrey;
    selectedStyleBorder.backgroundColor=Colors.white;
  }
  return <TouchableOpacity key={emotion.id}  style={{...emotionstyle,...selectedStyleBorder}} onPress={() => {selectEmotion(emotion);}}>  
    <Text style={{...emotiontextstyle,...selectedStyleText}}>{emotion.title}</Text>
    </TouchableOpacity>;
  };
  return (

    <View style={styles.container}>
        <View style={{flex:1}}  > 
        <ScrollView  contentContainerStyle={styles.content} >
        <View style={styles.innerContent} >
        <View style={styles.header}>
        {chapterIcon !== undefined && (
                    
                        <View
                          style={{
                            height: 51,
                            width: 51,
                            marginRight:20,
                            flexDirection: "column",
                            justifyContent: "center",
                            alignItems: "center",
                            backgroundColor:
                              Colors[props.subchapter.color],
                          }}
                        >
                          <Icon
                            color={Colors.white}
                            name={chapterIcon.name}
                            size={24}
                            type={chapterIcon.type}
                          />
                        </View>
        )}
        <Text style={styles.headerText}>{props.substep.title}</Text>
        </View>
        <View style={{ flex:1,  flexDirection: "row",
                      justifyContent: "center",
                      alignItems: "center",}}> 
                       <View style={{ flex:1,  flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",}}> 
        <View style={{width:80,height:80, backgroundColor:Colors.offWhite,borderRadius:80}}>
        { state.formdata.baseemotion &&  EMOTIONMAP[state.formdata.baseemotion.id]}
        </View>
        <Text style={baseemotiontext}>{state.formdata.baseemotion?.title}</Text>
        </View>
        </View>
            {/* {state.formdata.baseemotion && <><Text>{state.formdata.baseemotion.title}   </Text><TouchableOpacity onPress={() => {deselectBaseEmotion();}}><Text>x</Text></TouchableOpacity></>} */}
            {/* {props.substep.emotion_select_text && <Text style={styles.emotionselecttext}>{getTranslation(props.substep.emotion_select_text,state)}</Text>} */}
            <View style={styles.emotions}>
                {state.formdata.baseemotion && state.formdata.baseemotion.emotions && state.formdata.baseemotion.emotions.length>0 && state.formdata.baseemotion.emotions.map((emotion,index)=>(
                  RenderEmotion(emotion,index)
                ))}
                </View>
        </View>
        </ScrollView>
         </View> 
   

{/* <BottomChapterNav  />      */}
        
  </View>
        
  );
};
var styles = StyleSheet.create({
      container: {
        flex: 1,
        //height: Platform.OS === 'web' ? '100vh' : '100%',
        backgroundColor: Colors.darkGrey,
         flexDirection: "column",
         justifyContent: "space-between",
         alignItems: "stretch",
         padding:20,
        // position: "relative",
      },
      header:{
        flex: 1,
        flexDirection: "row",
        justifyContent: "flex-start",
        alignItems:"center",
      },
      // header:{
      //   color:Colors.white,
      //   fontSize:25,
      //   fontWeight:"600",
      //   lineHeight:25,
      //   width:"100%",
      //   textAlign:"left"
      // },
      headerText:{
        flex:1,
        fontSize:21,
        lineHeight:25,
        color:Colors.white
      }, 
      
      content: {
      // flex: 1,
        flexGrow: 1,
        flexDirection: "row",
        justifyContent: "center",
        alignItems:"flex-start",
        marginTop:60,
        paddingTop:30,
        paddingBottom:30,
       // alignItems: "flex-start",
      //  backgroundColor: Colors.green,
      },
      innerContent: {
        flex: 1,
        flexDirection: "column",
        justifyContent: "flex-start",
        alignItems:"stretch",
       // backgroundColor:Colors.pink,
       // flexWrap:"wrap",
       // alignItems: "flex-start",
       paddingBottom:150,
      //  maxWidth: 900,
      },
      emotions:{
        flex: 1,
        flexGrow:0,
        flexDirection: "row",
        flexWrap:"wrap",
        alignItems:"center",
        justifyContent: "center",
        marginTop:30
      },
      // emotion:{
      //    // minWidth:120,
      //     // height:120,
      //     borderRadius:70,
      //     borderColor:Colors.white,
      //     borderWidth:1,
      //      paddingTop:10,
      //      paddingBottom:10,
      //      paddingLeft:15,
      //      paddingRight:15,
      //      margin:10,
      //     // margin:20,
      // },
    //   emotionicon:{
    //   backgroundColor:Colors.salmon

    // },
      emotionselecttext: {
        ...Texts.bright,
        textAlign:"center",
        marginTop:10,
      },
      // emotiontext: {
      //   ...Texts.bright,
      //   fontSize:22,
      //   lineHeight:25,
      //   textAlign:"center",
      // // padding:10
      // },
  });
export default EmotionStep;