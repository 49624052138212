import React from 'react';
import { Colors, Buttons, Texts } from "../styles";
import { StyleSheet, Text, View, Dimensions } from 'react-native';
import {
    Icon,
  } from "react-native-elements";
{/* <Icon
                    color={Colors.white}
                    name={props.icon.replace("fas ","").replace("fa-","")}
                    size={60}
                    type="font-awesome"
                /> */}
  //debugger;
function IconIngress (props) {
        return (    
            <View  style={styles.ingress}>
                <View style={styles.iconbox}><Icon
                    color={Colors.white}
                    name={props.icon.replace("fas ","").replace("fa-","")}
                    size={60}
                    type="font-awesome-5"
                /></View>
                <Text style={styles.text}>{props.ingress}</Text>
            </View>)
}
const styles = StyleSheet.create({
        ingress: {
            fontWeight: "bold",
            margin:20,
            flex:1,
            flexDirection:"row",
            alignItems:"flex-start"
        },
        iconbox:{
            backgroundColor:Colors.salmon,
            width:60,
            height:60,
            marginRight:20
        },
        text:{
            ...Texts.bright
            //fontWeight:"bold"
        }
});



export default IconIngress;